.account {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .account {
    flex-direction: column;
  }
}
.account .phone {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.account .phone .select {
  width: 7.25rem;
  margin-top: -3px;
}
@media (max-width: 1200px) {
  .account .phone .select {
    margin-top: 0;
  }
}
.account .phone .select .select__label {
  opacity: 0;
}
.account .phone .select .select__container {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.account .phone .input .input__label {
  margin-left: -7.25rem;
}
.account .phone .input .MuiFilledInput-root {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.account__menu {
  margin-right: 8rem;
  width: 16rem;
}
@media (max-width: 1200px) {
  .account__menu {
    margin-right: 0;
    width: 100%;
  }
}
.account__menu__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #1f1f1f;
  text-transform: uppercase;
  display: none;
}
@media (max-width: 1200px) {
  .account__menu__title {
    display: block;
    font-size: 3.5rem;
  }
}
.account__menu__panel {
  padding: 1rem;
  border-radius: 1rem;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .account__menu__panel {
    margin-top: 1.5rem;
  }
}
.account__menu__panel__photo {
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  background-color: #1f1f1f;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.account__menu__panel__photo__empty {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
  text-transform: uppercase;
}
.account__menu__panel__photo img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.account__menu__panel__photo__add {
  position: absolute;
  right: 0.25rem;
  bottom: 0;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: #fdfdfd;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account__menu__panel__photo__add svg {
  width: 1rem;
  height: 1rem;
  color: #1f1f1f;
}
.account__menu__panel__photo input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.account__menu__panel__photo.has-photo {
  background-color: transparent;
}
.account__menu__panel__credit {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.account__menu__panel__credit__name {
  font-size: 0.875rem;
  line-height: 2.06;
  color: #1f1f1f;
}
.account__menu__panel__credit__value {
  margin-top: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.7px;
  text-align: left;
  color: #161616;
}
.account__menu__buttons {
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  .account__menu__buttons {
    margin-top: 3rem;
  }
}
.account__menu__buttons button {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 100%;
  justify-content: flex-start;
}
.account__menu__buttons button svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #1f1f1f;
}
.account__menu__buttons button span {
  margin-left: 1.5rem;
  line-height: 1.8;
  color: #1f1f1f;
  text-align: left;
}
@media (max-width: 1200px) {
  .account__menu__buttons button span {
    font-size: 0.875rem;
  }
}
.account__menu__buttons button.active,
.account__menu__buttons button:hover {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fdfdfd;
}
@media (max-width: 1200px) {
  .account__menu__buttons button.active,
  .account__menu__buttons button:hover {
    box-shadow: none;
    background-color: transparent;
  }
}
.account__content {
  flex: 1;
}
.account__content .account__menu__panel {
  display: none;
}
@media (max-width: 1200px) {
  .account__content .account__menu__panel {
    display: flex;
  }
}
.account__content .account-back {
  display: none;
}
@media (max-width: 1200px) {
  .account__content .account-back {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2rem;
  }
  .account__content .account-back svg {
    width: 1.5rem;
    height: 1.5rem;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .account__content .account-back span {
    margin-left: 2rem;
    font-size: 1rem;
    line-height: 1.8;
    color: #1f1f1f;
  }
}
.account__content__order {
  width: 62rem;
}
@media (max-width: 1200px) {
  .account__content__order {
    width: 100%;
  }
}
.account__content__order__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__order__title {
    font-size: 3.5rem;
  }
}
.account__content__order__header {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .account__content__order__header {
    margin-top: 4rem;
    flex-direction: column;
    align-items: flex-end;
  }
}
.account__content__order__header__left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .account__content__order__header__left {
    justify-content: space-between;
    width: 100%;
  }
}
.account__content__order__header__left__text {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.2;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__order__header__left__text {
    font-size: 1.25rem;
  }
}
.account__content__order__header__left__number {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-left: 5.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.2;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .account__content__order__header__left__number {
    font-size: 1.25rem;
    margin-left: 0;
  }
}
.account__content__order__header__right button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
}
@media (max-width: 1200px) {
  .account__content__order__header__right button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .account__content__order__header__right button {
    margin-top: 2rem;
  }
}
.account__content__order__payment-state {
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 2rem;
}
@media (max-width: 1200px) {
  .account__content__order__payment-state {
    margin-top: 3rem;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}
.account__content__order__payment-state__icon {
  min-width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}
@media (max-width: 1200px) {
  .account__content__order__payment-state__icon {
    min-width: 1rem;
    width: 1rem;
    height: 1rem;
    min-height: 1rem;
  }
}
.account__content__order__payment-state__icon svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #fff;
}
@media (max-width: 1200px) {
  .account__content__order__payment-state__icon svg {
    width: 1rem;
    height: 1rem;
  }
}
.account__content__order__payment-state__text {
  font-size: 1.5rem;
  color: #000;
  line-height: 1.4;
}
@media (max-width: 1200px) {
  .account__content__order__payment-state__text {
    font-size: 1.25rem;
    margin-left: 0.75rem;
    text-align: left;
    flex: 1;
  }
}
.account__content__order__payment-state__button {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
}
@media (max-width: 1200px) {
  .account__content__order__payment-state__button {
    margin-top: 4rem;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
.account__content__order__payment-state__button button:first-of-type {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fa0002;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  border: 0 !important;
  border-radius: 0 !important;
  width: 11rem;
  height: 3rem;
}
@media (max-width: 1200px) {
  .account__content__order__payment-state__button button:first-of-type {
    font-size: 0.875rem;
  }
}
.account__content__order__payment-state__button button:last-of-type {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-left: 1rem;
}
@media (max-width: 1200px) {
  .account__content__order__payment-state__button button:last-of-type {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .account__content__order__payment-state__button button:last-of-type {
    margin-left: 0;
    margin-top: 1rem;
  }
}
.account__content__order__payment-state.success .account__content__order__payment-state__icon {
  background-color: #409b04;
}
.account__content__order__payment-state.success .account__content__order__payment-state__text {
  color: #409b04;
}
.account__content__order__payment-state.waiting .account__content__order__payment-state__icon {
  background-color: #f39a33;
}
.account__content__order__payment-state.waiting .account__content__order__payment-state__icon svg {
  width: 1.25rem;
  height: 1.25rem;
}
@media (max-width: 1200px) {
  .account__content__order__payment-state.waiting .account__content__order__payment-state__icon svg {
    width: 0.75rem;
    height: 0.75rem;
  }
}
.account__content__order__payment-state.waiting .account__content__order__payment-state__text {
  color: #f39a33;
}
.account__content__order__payment-state.error {
  max-width: 33.75rem;
  height: auto;
}
.account__content__order__payment-state.error .account__content__order__payment-state__icon {
  background-color: #c24646;
}
.account__content__order__payment-state.error .account__content__order__payment-state__text {
  color: #c24646;
}
.account__content__order__transfer {
  margin-top: 4rem;
  padding: 4rem;
  border-radius: 1rem;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .account__content__order__transfer {
    margin: 4rem -1.625rem 0 -1.625rem;
    padding: 3rem 1.625rem;
    width: 100%;
    border-radius: 0;
    flex-direction: column;
  }
}
.account__content__order__transfer__qrcode {
  width: 11.25rem;
  margin-right: 13.5rem;
}
@media (max-width: 1200px) {
  .account__content__order__transfer__qrcode {
    width: 100%;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.account__content__order__transfer__qrcode__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__order__transfer__qrcode__title {
    font-size: 1.25rem;
  }
}
.account__content__order__transfer__qrcode img {
  margin-top: 2rem;
  width: 11.25rem;
}
@media (max-width: 1200px) {
  .account__content__order__transfer__qrcode img {
    margin-top: 1rem;
    width: 9.8125rem;
  }
}
.account__content__order__transfer__info {
  flex: 1;
}
@media (max-width: 1200px) {
  .account__content__order__transfer__info {
    margin-top: 4rem;
  }
}
.account__content__order__transfer__info__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
@media (max-width: 1200px) {
  .account__content__order__transfer__info__title {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
}
.account__content__order__transfer__info__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;
}
@media (max-width: 1200px) {
  .account__content__order__transfer__info__item {
    margin-bottom: 0;
    margin-top: 1.5rem;
  }
}
.account__content__order__transfer__info__item__name {
  font-size: 1rem;
  color: #1f1f1f;
  width: 13.4375rem;
}
@media (max-width: 1200px) {
  .account__content__order__transfer__info__item__name {
    font-size: 0.875rem;
    width: 7rem;
  }
}
.account__content__order__transfer__info__item__value {
  font-weight: 500;
  color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .account__content__order__transfer__info__item__value {
    font-size: 0.875rem;
    text-align: left;
  }
}
.account__content__order__transfer__info__item__value svg {
  margin-left: 1rem;
  color: #8b8b8b;
  cursor: pointer;
}
.account__content__order__transfer__info__item:last-of-type {
  margin-bottom: 0;
}
.account__content__order__panels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.account__content__order__panels__panel {
  margin-top: 4rem;
  margin-right: 13.5rem;
  width: calc((100% - 13.5rem) / 2);
}
@media (max-width: 1200px) {
  .account__content__order__panels__panel {
    margin-right: 1.5rem;
    width: calc((100% - 1.5rem) / 2);
  }
}
.account__content__order__panels__panel__value {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
}
.account__content__order__panels__panel__value__name {
  min-width: 16rem;
  width: 16rem;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .account__content__order__panels__panel__value__name {
    min-width: 50%;
    width: 50%;
    font-size: 0.875rem;
  }
}
.account__content__order__panels__panel__value__text {
  font-weight: 600;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .account__content__order__panels__panel__value__text {
    font-size: 0.875rem;
  }
}
.account__content__order__panels__panel__value__text a {
  margin-left: 0.5rem;
  font-weight: 600;
  line-height: 1.8;
  color: #8b8b8b;
  text-decoration: underline;
}
.account__content__order__panels__panel__value:last-of-type {
  margin-bottom: 0;
}
.account__content__order__panels__panel__buttons {
  display: flex;
  flex-direction: column;
}
.account__content__order__panels__panel__buttons button:first-of-type {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-bottom: 1.5rem;
  width: 11rem;
  height: 3rem;
  white-space: nowrap;
}
@media (max-width: 1200px) {
  .account__content__order__panels__panel__buttons button:first-of-type {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
.account__content__order__panels__panel__buttons button:last-of-type {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fa0002;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  border: 0 !important;
  border-radius: 0 !important;
  width: 11rem;
  height: 3rem;
}
@media (max-width: 1200px) {
  .account__content__order__panels__panel__buttons button:last-of-type {
    font-size: 0.875rem;
  }
}
.account__content__order__panels__panel__address__title {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.7px;
  color: #161616;
  text-transform: uppercase;
}
.account__content__order__panels__panel__address__value {
  margin-top: 1.5rem;
  line-height: 2;
  text-align: left;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .account__content__order__panels__panel__address__value {
    font-size: 0.875rem;
    margin-top: 1rem;
  }
}
.account__content__order__panels__panel:nth-child(2n + 2) {
  margin-right: 0;
}
@media (max-width: 1200px) {
  .account__content__order__panels__panel:first-of-type {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 1200px) {
  .account__content__order__panels__panel:nth-child(2) {
    display: none;
  }
}
.account__content__order__products {
  margin-top: 5.5rem;
  width: 58rem;
  padding-bottom: 3rem;
}
@media (max-width: 1200px) {
  .account__content__order__products {
    width: auto;
    overflow-x: scroll;
    margin-top: 4rem;
  }
}
.account__content__order__products__product {
  margin-bottom: 2rem;
}
@media (max-width: 1200px) {
  .account__content__order__products__product {
    width: 40rem;
  }
}
.account__content__order__products__product__up {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 4rem;
}
@media (max-width: 1200px) {
  .account__content__order__products__product__up {
    min-height: 2.5rem;
  }
}
.account__content__order__products__product__up__left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.account__content__order__products__product__up__left__photo {
  width: 4rem;
  height: 4rem;
}
@media (max-width: 1200px) {
  .account__content__order__products__product__up__left__photo {
    width: 2.5rem;
    height: 2.5rem;
  }
}
.account__content__order__products__product__up__left__photo img {
  width: 100%;
  mix-blend-mode: multiply;
}
.account__content__order__products__product__up__info {
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
@media (max-width: 1200px) {
  .account__content__order__products__product__up__info {
    padding: 0 0.5rem;
  }
}
.account__content__order__products__product__up__info__name {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.9px;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__order__products__product__up__info__name {
    font-size: 0.875rem;
  }
}
.account__content__order__products__product__up__stock {
  width: 6.25rem;
  text-align: right;
  white-space: nowrap;
}
@media (max-width: 1200px) {
  .account__content__order__products__product__up__stock {
    font-size: 0.875rem;
    width: 6rem;
  }
}
.account__content__order__products__product__up__stock.instock {
  color: #409b04;
}
.account__content__order__products__product__up__stock.order {
  color: #f39a33;
}
.account__content__order__products__product__up__count {
  width: 8rem;
  text-align: right;
}
@media (max-width: 1200px) {
  .account__content__order__products__product__up__count {
    font-size: 0.875rem;
    width: 4rem;
  }
}
.account__content__order__products__product__up__unitprice {
  width: 9rem;
  text-align: right;
}
@media (max-width: 1200px) {
  .account__content__order__products__product__up__unitprice {
    font-size: 0.875rem;
    width: 8rem;
  }
}
.account__content__order__products__product__up__price {
  color: #1f1f1f;
  font-size: 1rem;
  text-align: right;
  width: 9.5rem;
  font-weight: 600;
}
@media (max-width: 1200px) {
  .account__content__order__products__product__up__price {
    font-size: 0.875rem;
    width: 8rem;
  }
}
.account__content__order__products__product__up.with-extensions {
  align-items: flex-start;
}
.account__content__order__products__product__extensions {
  margin-top: -1.5rem;
}
@media (max-width: 1200px) {
  .account__content__order__products__product__extensions {
    margin-top: -0.5rem;
  }
}
.account__content__order__products__product__extensions__extension {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 8rem;
}
@media (max-width: 1200px) {
  .account__content__order__products__product__extensions__extension {
    padding-left: 3rem;
  }
}
.account__content__order__products__product__extensions__extension__name {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .account__content__order__products__product__extensions__extension__name {
    font-size: 0.625rem;
  }
}
.account__content__order__products__product__extensions__extension__name svg {
  margin-left: 0.25rem;
  width: 1rem;
  height: 1rem;
  color: #8b8b8b;
}
.account__content__order__products__product__extensions__extension__price {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .account__content__order__products__product__extensions__extension__price {
    font-size: 0.625rem;
  }
}
.account__content__order__products__product__extensions__extension:first-of-type {
  margin-top: 0;
}
.account__content__order__products__product:last-of-type {
  margin-bottom: 0;
}
.account__content__order__summary {
  margin-top: 3rem;
  width: 22.125rem;
  margin-left: 35.875rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 1rem;
  background-color: #f7f7f7;
  padding: 2rem;
}
@media (max-width: 1200px) {
  .account__content__order__summary {
    width: 100%;
    margin: 5.5rem -1.625rem -3rem -1.625rem;
    padding: 2rem 1.625rem;
    border-radius: 0;
  }
}
.account__content__order__summary__amounts {
  width: 100%;
}
.account__content__order__summary__amounts__item {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.account__content__order__summary__amounts__item__name {
  font-size: 1rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .account__content__order__summary__amounts__item__name {
    font-size: 0.875rem;
  }
}
.account__content__order__summary__amounts__item__value {
  font-size: 1rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .account__content__order__summary__amounts__item__value {
    font-size: 0.875rem;
  }
}
.account__content__order__summary__amounts__item:last-of-type {
  margin-bottom: 0;
}
.account__content__order__summary__amount {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.account__content__order__summary__amount__name {
  font-size: 1.5rem;
  color: #000;
}
@media (max-width: 1200px) {
  .account__content__order__summary__amount__name {
    font-size: 1.25rem;
  }
}
.account__content__order__summary__amount__value {
  font-size: 1.375rem;
  color: #000;
}
@media (max-width: 1200px) {
  .account__content__order__summary__amount__value {
    font-size: 1.25rem;
  }
}
.account__content__order__summary__vat {
  margin-top: 1rem;
  width: 100%;
}
.account__content__order__summary__vat__item {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.account__content__order__summary__vat__item__name {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .account__content__order__summary__vat__item__name {
    font-size: 0.625rem;
  }
}
.account__content__order__summary__vat__item__value {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .account__content__order__summary__vat__item__value {
    font-size: 0.625rem;
  }
}
.account__content__order__summary__vat__item:last-of-type {
  margin-bottom: 0;
}
.account__content__reviews {
  width: 46rem;
}
@media (max-width: 1200px) {
  .account__content__reviews {
    width: 100%;
  }
}
.account__content__reviews__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__reviews__title {
    font-size: 3.5rem;
  }
}
.account__content__reviews__subtitle {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding-top: 3rem;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.2;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__reviews__subtitle {
    font-size: 1.25rem;
    padding-top: 4rem;
  }
}
.account__content__reviews__table {
  margin-top: 2.5rem;
}
@media (max-width: 1200px) {
  .account__content__reviews__table {
    margin-top: 3rem;
    overflow-x: scroll;
  }
}
.account__content__reviews__table__line {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.account__content__reviews__table__line__cell {
  flex: 1;
  padding: 1.5rem 0;
  border-top: 2px solid #8b8b8b;
}
@media (max-width: 1200px) {
  .account__content__reviews__table__line__cell {
    font-size: 0.875rem;
  }
}
@media (max-width: 1200px) {
  .account__content__reviews__table__line__cell:nth-child(1) {
    min-width: 12rem;
  }
}
.account__content__reviews__table__line__cell:nth-child(1) button {
  color: #8b8b8b;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .account__content__reviews__table__line__cell:nth-child(1) button {
    font-size: 0.875rem;
  }
}
.account__content__reviews__table__line__cell:nth-child(2) {
  width: 8rem;
  max-width: 8rem;
  min-width: 8rem;
}
@media (max-width: 1200px) {
  .account__content__reviews__table__line__cell:nth-child(2) {
    width: 7.5rem;
    max-width: 7.5rem;
    min-width: 7.5rem;
  }
}
.account__content__reviews__table__line__cell:nth-child(3) {
  width: 8rem;
  max-width: 8rem;
  min-width: 8rem;
}
@media (max-width: 1200px) {
  .account__content__reviews__table__line__cell:nth-child(3) {
    width: 7.5rem;
    max-width: 7.5rem;
    min-width: 7.5rem;
  }
}
.account__content__reviews__table__line__cell:nth-child(3) svg {
  width: 1rem;
  height: 1rem;
}
.account__content__reviews__table__line__cell:nth-child(3) button {
  color: #8b8b8b;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .account__content__reviews__table__line__cell:nth-child(3) button {
    font-size: 0.875rem;
  }
}
.account__content__reviews__table__line__cell:nth-child(4) {
  width: 14rem;
  max-width: 14rem;
  min-width: 14rem;
}
.account__content__reviews__table__line__cell:nth-child(4) span {
  line-height: 1.8;
}
.account__content__reviews__table__line__cell:nth-child(4) button {
  margin-top: 1rem;
  font-size: 0.75rem;
  display: block;
  color: #8b8b8b;
  text-decoration: underline;
}
.account__content__reviews__table__line:first-of-type .account__content__reviews__table__line__cell {
  font-weight: 600;
  border-top: 0;
}
.account__content__orders {
  width: 54rem;
}
@media (max-width: 1200px) {
  .account__content__orders {
    width: 100%;
  }
}
.account__content__orders__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__orders__title {
    font-size: 3.5rem;
  }
}
.account__content__orders__subtitle {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding-top: 3rem;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.2;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__orders__subtitle {
    padding-top: 4rem;
    font-size: 1.25rem;
  }
}
.account__content__orders__table {
  margin-top: 2.5rem;
}
@media (max-width: 1200px) {
  .account__content__orders__table {
    margin-top: 3rem;
    overflow-x: scroll;
  }
}
.account__content__orders__table__line {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.account__content__orders__table__line__cell {
  flex: 1;
  padding: 1.5rem 0;
  border-top: 2px solid #8b8b8b;
}
@media (max-width: 1200px) {
  .account__content__orders__table__line__cell {
    font-size: 0.875rem;
  }
}
@media (max-width: 1200px) {
  .account__content__orders__table__line__cell:nth-child(1) {
    min-width: 10rem;
  }
}
.account__content__orders__table__line__cell:nth-child(1) button {
  color: #8b8b8b;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .account__content__orders__table__line__cell:nth-child(1) button {
    font-size: 0.875rem;
  }
}
.account__content__orders__table__line__cell:nth-child(2) {
  width: 8rem;
  max-width: 8rem;
  min-width: 8rem;
}
@media (max-width: 1200px) {
  .account__content__orders__table__line__cell:nth-child(2) {
    width: 7.5rem;
    max-width: 7.5rem;
    min-width: 7.5rem;
  }
}
.account__content__orders__table__line__cell:nth-child(3) {
  width: 8rem;
  max-width: 8rem;
  min-width: 8rem;
}
@media (max-width: 1200px) {
  .account__content__orders__table__line__cell:nth-child(3) {
    width: 7.5rem;
    max-width: 7.5rem;
    min-width: 7.5rem;
  }
}
.account__content__orders__table__line__cell:nth-child(4) {
  width: 16rem;
  max-width: 16rem;
  min-width: 16rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .account__content__orders__table__line__cell:nth-child(4) {
    width: 12rem;
    max-width: 12rem;
    min-width: 12rem;
  }
}
.account__content__orders__table__line__cell:nth-child(4) img {
  margin-right: 0.5rem;
  height: 1.5rem;
  mix-blend-mode: multiply;
}
.account__content__orders__table__line__cell:nth-child(4) a {
  font-size: 0.75rem;
  line-height: 1.4;
  color: #8b8b8b;
  text-decoration: underline;
}
.account__content__orders__table__line__cell:nth-child(5) {
  width: 8rem;
  max-width: 8rem;
  min-width: 8rem;
}
@media (max-width: 1200px) {
  .account__content__orders__table__line__cell:nth-child(5) {
    width: 7.5rem;
    max-width: 7.5rem;
    min-width: 7.5rem;
  }
}
.account__content__orders__table__line:first-of-type .account__content__orders__table__line__cell {
  font-weight: 600;
  border-top: 0;
}
.account__content__credit {
  width: 46rem;
}
@media (max-width: 1200px) {
  .account__content__credit {
    width: 100%;
  }
}
.account__content__credit__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__credit__title {
    font-size: 3.5rem;
  }
}
.account__content__credit__panel {
  margin-top: 3rem;
}
@media (max-width: 1200px) {
  .account__content__credit__panel {
    margin-top: 4rem;
  }
}
.account__content__credit__panel__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.2;
  color: #1f1f1f;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__credit__panel__title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
}
.account__content__credit__panel__line {
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .account__content__credit__panel__line {
    margin-bottom: 1rem;
    justify-content: space-between;
  }
}
.account__content__credit__panel__line__name {
  line-height: 1.8;
  color: #1f1f1f;
  width: 16rem;
}
@media (max-width: 1200px) {
  .account__content__credit__panel__line__name {
    width: auto;
    font-size: 0.875rem;
  }
}
.account__content__credit__panel__line__value {
  font-weight: 600;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .account__content__credit__panel__line__value {
    font-size: 0.875rem;
  }
}
.account__content__credit__table {
  margin-top: 2.5rem;
}
@media (max-width: 1200px) {
  .account__content__credit__table {
    margin-top: 3rem;
    overflow-x: scroll;
  }
}
.account__content__credit__table__line {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.account__content__credit__table__line__cell {
  flex: 1;
  padding: 1.5rem 0;
  border-top: 2px solid #8b8b8b;
}
@media (max-width: 1200px) {
  .account__content__credit__table__line__cell {
    font-size: 0.875rem;
  }
}
.account__content__credit__table__line__cell:nth-child(1) {
  width: 8rem;
  max-width: 8rem;
  min-width: 8rem;
}
@media (max-width: 1200px) {
  .account__content__credit__table__line__cell:nth-child(1) {
    width: 6rem;
    max-width: 6rem;
    min-width: 6rem;
  }
}
@media (max-width: 1200px) {
  .account__content__credit__table__line__cell:nth-child(2) {
    min-width: 10rem;
  }
}
.account__content__credit__table__line__cell:nth-child(2) button {
  color: #8b8b8b;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .account__content__credit__table__line__cell:nth-child(2) button {
    font-size: 0.875rem;
  }
}
.account__content__credit__table__line__cell:nth-child(3) {
  width: 8rem;
  max-width: 8rem;
  min-width: 8rem;
}
@media (max-width: 1200px) {
  .account__content__credit__table__line__cell:nth-child(3) {
    width: 7.5rem;
    max-width: 7.5rem;
    min-width: 7.5rem;
  }
}
.account__content__credit__table__line__cell:nth-child(4) {
  width: 8rem;
  max-width: 8rem;
  min-width: 8rem;
}
@media (max-width: 1200px) {
  .account__content__credit__table__line__cell:nth-child(4) {
    width: 7.5rem;
    max-width: 7.5rem;
    min-width: 7.5rem;
  }
}
.account__content__credit__table__line:first-of-type .account__content__credit__table__line__cell {
  font-weight: 600;
  border-top: 0;
}
.account__content__credit__table .account__content__credit__table__line.credit svg {
  color: #409b04;
}
.account__content__credit__table .account__content__credit__table__line.credit .account__content__credit__table__line__cell:nth-child(3),
.account__content__credit__table .account__content__credit__table__line.credit .account__content__credit__table__line__cell:last-of-type {
  color: #409b04;
}
.account__content__credit__table .account__content__credit__table__line.debet svg {
  color: #c24646;
}
.account__content__credit__table .account__content__credit__table__line.debet .account__content__credit__table__line__cell:nth-child(3),
.account__content__credit__table .account__content__credit__table__line.debet .account__content__credit__table__line__cell:last-of-type {
  color: #c24646;
}
.account__content__settings {
  width: 46rem;
}
@media (max-width: 1200px) {
  .account__content__settings {
    width: 100%;
  }
}
.account__content__settings .input__left {
  top: auto;
  bottom: 0;
}
.account__content__settings__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__settings__title {
    font-size: 3.5rem;
  }
}
.account__content__settings__password {
  margin-top: 3rem;
}
@media (max-width: 1200px) {
  .account__content__settings__password {
    margin-top: 4rem;
  }
}
.account__content__settings__password__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__settings__password__title {
    font-size: 1.25rem;
  }
}
.account__content__settings__password__data {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.5rem;
}
@media (max-width: 1200px) {
  .account__content__settings__password__data {
    flex-direction: column;
    align-items: flex-end;
    margin-top: 1rem;
  }
}
.account__content__settings__password__data__text {
  flex: 1;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .account__content__settings__password__data__text {
    font-size: 0.875rem;
    line-height: 2.06;
    width: 100%;
  }
}
.account__content__settings__password__data__email {
  flex: 1;
  font-weight: 600;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .account__content__settings__password__data__email {
    font-size: 0.875rem;
    margin-top: 1rem;
    width: 100%;
  }
}
.account__content__settings__password__data button {
  flex: 1;
  font-weight: 300;
  line-height: 1.75;
  color: #8b8b8b;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .account__content__settings__password__data button {
    font-size: 0.875rem;
    line-height: 2;
    margin-top: 1.5rem;
  }
}
.account__content__settings__password__settings {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 1200px) {
  .account__content__settings__password__settings {
    margin-top: 0.5rem;
  }
}
.account__content__settings__password__settings > .input {
  margin-top: 1.5rem;
  width: calc((100% - 2rem) / 2);
}
@media (max-width: 1200px) {
  .account__content__settings__password__settings > .input {
    width: 100%;
  }
}
.account__content__settings__password__settings > .input:first-of-type {
  margin-right: 40%;
}
@media (max-width: 1200px) {
  .account__content__settings__password__settings > .input:first-of-type {
    margin-right: 0;
  }
}
.account__content__settings__password__settings > .input:nth-child(2) {
  margin-right: 2rem;
}
@media (max-width: 1200px) {
  .account__content__settings__password__settings > .input:nth-child(2) {
    margin-right: 0;
  }
}
.account__content__settings__contact {
  margin-top: 3rem;
}
@media (max-width: 1200px) {
  .account__content__settings__contact {
    margin-top: 5.5rem;
  }
}
.account__content__settings__contact__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__settings__contact__title {
    font-size: 1.25rem;
  }
}
.account__content__settings__contact__inputs {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .account__content__settings__contact__inputs {
    flex-direction: column;
  }
}
.account__content__settings__contact__inputs .input {
  flex: 1;
}
.account__content__settings__contact__inputs .input:first-of-type {
  margin-right: 2rem;
  max-width: 21.25rem;
}
@media (max-width: 1200px) {
  .account__content__settings__contact__inputs .input:first-of-type {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}
.account__content__settings__contact__inputs > .select:first-of-type {
  margin-right: 2rem;
  max-width: 21.25rem;
}
@media (max-width: 1200px) {
  .account__content__settings__contact__inputs > .select:first-of-type {
    margin-right: 0;
    max-width: 100%;
  }
}
.account__content__settings__contact > .input {
  margin-top: 2rem;
  width: calc((100% - 2rem) / 2);
}
.account__content__settings__address {
  margin: 3rem 0 1.5rem 0;
}
@media (max-width: 1200px) {
  .account__content__settings__address {
    margin-top: 5.5rem;
  }
}
.account__content__settings__address__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__settings__address__title {
    font-size: 1.25rem;
  }
}
.account__content__settings__address > .checkbox {
  margin-top: 1.5rem;
}
.account__content__settings__address__inputs {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .account__content__settings__address__inputs {
    flex-direction: column;
  }
}
.account__content__settings__address__inputs .input {
  flex: 1;
}
.account__content__settings__address__inputs .input:first-of-type {
  margin-right: 2rem;
  max-width: 21.25rem;
}
@media (max-width: 1200px) {
  .account__content__settings__address__inputs .input:first-of-type {
    margin-right: 0;
    max-width: 100%;
    margin-bottom: 1.5rem;
  }
}
.account__content__settings__address__inputs > .select:first-of-type {
  margin-right: 2rem;
  max-width: 21.25rem;
}
@media (max-width: 1200px) {
  .account__content__settings__address__inputs > .select:first-of-type {
    margin-right: 0;
    max-width: 100%;
  }
}
.account__content__settings__address__manual,
.account__content__settings__address button.account__content__settings__address__manual {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 300;
  color: #8b8b8b;
  text-decoration: underline;
  display: block;
}
@media (max-width: 1200px) {
  .account__content__settings__address__manual,
  .account__content__settings__address button.account__content__settings__address__manual {
    font-size: 0.875rem;
  }
}
.account__content__settings__address > .input,
.account__content__settings__address > .select {
  margin-top: 2rem;
}
.account__content__settings__delivery {
  margin-top: 1.5rem;
}
@media (max-width: 1200px) {
  .account__content__settings__delivery {
    margin-top: 4rem;
  }
}
.account__content__settings__delivery__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .account__content__settings__delivery__title {
    font-size: 1.25rem;
  }
}
.account__content__settings__delivery > .checkbox {
  margin-top: 1.5rem;
}
.account__content__settings__delivery__inputs {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
}
.account__content__settings__delivery__inputs .input {
  flex: 1;
}
.account__content__settings__delivery__inputs .input:first-of-type {
  margin-right: 2rem;
  max-width: 21.25rem;
}
.account__content__settings__delivery__inputs > .select:first-of-type {
  margin-right: 2rem;
  max-width: 21.25rem;
}
.account__content__settings__delivery__manual,
.account__content__settings__delivery button.account__content__settings__delivery__manual {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 300;
  color: #8b8b8b;
  text-decoration: underline;
  display: block;
}
@media (max-width: 1200px) {
  .account__content__settings__delivery__manual,
  .account__content__settings__delivery button.account__content__settings__delivery__manual {
    font-size: 0.875rem;
  }
}
.account__content__settings__delivery > .input,
.account__content__settings__delivery > .select {
  margin-top: 2rem;
}
.account__content__settings__button,
.account__content__settings button.account__content__settings__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fa0002;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  border: 0 !important;
  border-radius: 0 !important;
  margin-top: 3rem;
  width: 100%;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .account__content__settings__button,
  .account__content__settings button.account__content__settings__button {
    font-size: 0.875rem;
  }
}

.public__header {
  height: 54rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .public__header {
    height: auto;
    flex-direction: column-reverse;
  }
}
.public__header__left {
  position: relative;
  flex: 1;
  margin-left: 9rem;
  display: flex;
  flex-direction: column;
  max-width: 28.5rem;
}
@media (max-width: 1200px) {
  .public__header__left {
    max-width: 100%;
    margin-left: 0;
  }
}
.public__header__left__up {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.public__header__left__up__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 11rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  color: #1f1f1f;
  white-space: nowrap;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .public__header__left__up__title {
    font-size: 5rem;
  }
}
.public__header__left__up__text {
  margin-top: 0.5rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 1.17;
  color: #000;
  font-weight: normal;
}
@media (max-width: 1200px) {
  .public__header__left__up__text {
    font-size: 1.125rem;
  }
}
.public__header__left__up__button,
.public__header__left__up button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fa0002;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  border: 0 !important;
  border-radius: 0 !important;
  margin-top: 4rem;
  width: 11rem;
}
@media (max-width: 1200px) {
  .public__header__left__up__button,
  .public__header__left__up button {
    font-size: 0.875rem;
  }
}
@media (max-width: 1200px) {
  .public__header__left__up__button,
  .public__header__left__up button {
    margin-top: 2rem;
    width: 9.8125rem;
    height: 2.5rem;
  }
}
.public__header__left__down {
  padding-bottom: 3rem;
  border-top: 2px solid #f5f5f5;
  width: calc(100% + 7rem);
}
@media (max-width: 1200px) {
  .public__header__left__down {
    padding-bottom: 0;
    width: 100%;
    margin-top: 4rem;
  }
}
.public__header__left__down__title {
  padding-top: 1.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #161616;
  text-transform: uppercase;
}
.public__header__left__down__partners {
  margin-top: 1.5rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .public__header__left__down__partners {
    margin-top: 1rem;
    height: 2rem;
  }
}
.public__header__left__down__partners button:first-of-type svg {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.public__header__left__down__partners svg {
  width: 3rem;
  height: 3rem;
  color: #8b8b8b;
}
@media (max-width: 1200px) {
  .public__header__left__down__partners svg {
    width: 2rem;
    height: 2rem;
  }
}
.public__header__left__down__partners__content {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4rem;
  flex-wrap: nowrap;
  margin: 0.5rem;
}
.public__header__left__down__partners__content img {
  margin-right: 2rem;
  width: auto;
  height: 2rem;
  color: #8b8b8b;
}
@media (max-width: 1200px) {
  .public__header__left__down__partners__content img {
    width: 2rem;
    height: 2rem;
  }
}
.public__header__photo {
  min-width: 54rem;
  width: 54rem;
  height: 54rem;
  border-radius: 100%;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .public__header__photo {
    min-width: 100%;
    width: 100%;
    max-width: 54rem;
    height: auto;
  }
}
.public__header__photo img {
  width: 100%;
}
.public__stats {
  margin: 9rem auto 0 auto;
  width: 62.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .public__stats {
    margin-top: 7rem;
    width: 100%;
    flex-direction: column;
  }
}
.public__stats__panel {
  width: 22rem;
}
@media (max-width: 1200px) {
  .public__stats__panel {
    width: 100%;
  }
}
.public__stats__panel__text {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.4;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .public__stats__panel__text {
    font-size: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
.public__stats__panel__subtext {
  padding-top: 1.5rem;
  line-height: 1.8;
  color: #030303;
  font-size: 1.25rem;
  font-weight: normal;
}
@media (max-width: 1200px) {
  .public__stats__panel__subtext {
    font-size: 0.875rem;
    padding-top: 0;
    padding-bottom: 2rem;
  }
}
.public__stats__panel__item {
  margin-bottom: 5.5rem;
}
@media (max-width: 1200px) {
  .public__stats__panel__item {
    margin-bottom: 4rem;
    padding-left: 7rem;
  }
}
.public__stats__panel__item__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .public__stats__panel__item__title {
    font-size: 1.5rem;
  }
}
.public__stats__panel__item__text {
  margin-top: 2rem;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .public__stats__panel__item__text {
    margin-top: 1rem;
    font-size: 0.875rem;
  }
}
.public__stats__panel__item:last-of-type {
  margin-bottom: 0;
}
.public__categories {
  margin-top: 9rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 1200px) {
  .public__categories {
    flex-direction: column;
  }
}
.public__categories__category,
.public__categories button.public__categories__category {
  position: relative;
  margin-right: 2rem;
  margin-bottom: 2rem;
  width: calc((100% - 2rem * 3) / 4);
  padding: 2rem;
  border-radius: 0;
  background-color: #fff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  height: 12.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .public__categories__category,
  .public__categories button.public__categories__category {
    margin-right: 0;
    margin-bottom: 1rem;
    width: 100%;
    padding: 1.5rem;
    height: 9rem;
  }
}
.public__categories__category img,
.public__categories button.public__categories__category img {
  position: absolute;
  right: -5rem;
  bottom: -5rem;
  height: 16.25rem;
  mix-blend-mode: multiply;
}
@media (max-width: 1200px) {
  .public__categories__category img,
  .public__categories button.public__categories__category img {
    height: 12rem;
    right: -3rem;
    bottom: -3rem;
  }
}
.public__categories__category__name,
.public__categories button.public__categories__category__name {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .public__categories__category__name,
  .public__categories button.public__categories__category__name {
    font-size: 1.25rem;
  }
}
.public__categories__category__link,
.public__categories button.public__categories__category__link {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.public__categories__category__link svg,
.public__categories button.public__categories__category__link svg {
  width: 2rem;
  height: 2rem;
  color: #8b8b8b;
}
@media (max-width: 1200px) {
  .public__categories__category__link svg,
  .public__categories button.public__categories__category__link svg {
    width: 1.75rem;
    height: 1.75rem;
  }
}
.public__categories__category__link span,
.public__categories button.public__categories__category__link span {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-left: 0.75rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: 0.8px;
  color: #8b8b8b;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .public__categories__category__link span,
  .public__categories button.public__categories__category__link span {
    font-size: 0.875rem;
  }
}
.public__categories__category:hover,
.public__categories button.public__categories__category:hover {
  box-shadow: none;
}
.public__categories__category:nth-child(4n + 4),
.public__categories button.public__categories__category:nth-child(4n + 4) {
  margin-right: 0;
}
@media (max-width: 1200px) {
  .public__categories__category:last-of-type {
    margin-bottom: 0;
  }
}
.public__goalies {
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  .public__goalies {
    margin-top: 7rem;
  }
}
.public__goalies__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .public__goalies__header {
    flex-direction: column;
  }
}
.public__goalies__header__left {
  width: 38rem;
}
@media (max-width: 1200px) {
  .public__goalies__header__left {
    width: 100%;
  }
}
.public__goalies__header__left__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .public__goalies__header__left__title {
    font-size: 2.5rem;
  }
}
.public__goalies__header__left__text {
  margin-top: 3rem;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .public__goalies__header__left__text {
    font-size: 0.875rem;
  }
}
@media (max-width: 1200px) {
  .public__goalies__header__button,
  .public__goalies__header button.public__goalies__header__button {
    display: none;
  }
}
.public__goalies__header__button svg,
.public__goalies__header button.public__goalies__header__button svg {
  width: 2rem;
  height: 2rem;
  color: #8b8b8b;
}
.public__goalies__header__button span,
.public__goalies__header button.public__goalies__header__button span {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-left: 0.75rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: 0.8px;
  color: #8b8b8b;
  text-transform: uppercase;
}
.public__goalies__content {
  position: relative;
  padding-left: 8rem;
}
@media (max-width: 1200px) {
  .public__goalies__content {
    padding-left: 0;
  }
}
.public__goalies__content .swiper {
  position: initial;
}
.public__goalies__content .swiper-scrollbar {
  left: 8rem !important;
  width: calc(100% - 8rem) !important;
}
.public__goalies__content__arrows {
  position: absolute;
  left: 0;
  top: 7rem;
  width: 7rem;
  height: 16.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
@media (max-width: 1200px) {
  .public__goalies__content__arrows {
    display: none;
  }
}
.public__goalies__content__arrows svg {
  width: 3rem;
  height: 3rem;
  color: #1f1f1f;
  opacity: 0.5;
  cursor: pointer;
}
.public__goalies__content__arrows svg:first-of-type {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.public__goalies__content .goaly {
  min-width: 23.5rem;
  padding-bottom: 2rem;
}
@media (max-width: 1200px) {
  .public__goalies__content .goaly {
    width: 9.8125rem;
    min-width: 9.8125rem;
    margin-right: 0.5rem;
    margin-top: 5.5rem;
  }
}
@media (max-width: 1200px) {
  .public__goalies__content .goaly .goaly__photo {
    height: 11.125rem;
  }
}
@media (max-width: 1200px) {
  .public__goalies__content .goaly .goaly__photo img {
    height: 100%;
    width: auto;
  }
}
@media (max-width: 1200px) {
  .public__goalies__content .goaly .goaly__header {
    margin-top: 1rem;
    height: auto;
  }
}
@media (max-width: 1200px) {
  .public__goalies__content .goaly .goaly__header svg {
    display: none;
  }
}
@media (max-width: 1200px) {
  .public__goalies__content .goaly .goaly__header .goaly__header__info {
    margin-left: 0;
  }
}
@media (max-width: 1200px) {
  .public__goalies__content .goaly .goaly__header .goaly__header__info .goaly__header__info__name {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  }
}
@media (max-width: 1200px) {
  .public__goalies__content .goaly .goaly__text {
    display: none;
  }
}
.public__products {
  margin-top: 11rem;
}
@media (max-width: 1200px) {
  .public__products {
    margin-top: 7rem;
  }
}
.public__products__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .public__products__title {
    font-size: 2.5rem;
  }
}
.public__products__buttons {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .public__products__buttons {
    margin-top: 2rem;
  }
}
.public__products__buttons__left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .public__products__buttons__left {
    flex: 1;
  }
}
.public__products__buttons__left__button,
.public__products__buttons__left button.public__products__buttons__left__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-right: 1rem;
}
@media (max-width: 1200px) {
  .public__products__buttons__left__button,
  .public__products__buttons__left button.public__products__buttons__left__button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .public__products__buttons__left__button,
  .public__products__buttons__left button.public__products__buttons__left__button {
    flex: 1;
    margin-right: 0.5rem;
  }
}
.public__products__buttons__left__button:last-of-type,
.public__products__buttons__left button.public__products__buttons__left__button:last-of-type {
  margin-right: 0;
}
.public__products__buttons__left button.public__products__buttons__left__button.active {
  box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #1f1f1f;
  color: #fefefe;
}
@media (max-width: 1200px) {
  .public__products__buttons__right {
    display: none;
  }
}
.public__products__buttons__right__button svg,
.public__products__buttons__right button.public__products__buttons__right__button svg {
  width: 2rem;
  height: 2rem;
  color: #8b8b8b;
}
.public__products__buttons__right__button span,
.public__products__buttons__right button.public__products__buttons__right__button span {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-left: 0.75rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: 0.8px;
  color: #8b8b8b;
  text-transform: uppercase;
}
.public__products .products-list {
  margin-top: 5.5rem;
}
@media (max-width: 1200px) {
  .public__products .products-list {
    margin-top: 0;
  }
}
.public__products .products-list button.products-list__content__item {
  width: calc((100% - 2rem * 3) / 4);
}
@media (max-width: 1200px) {
  .public__products .products-list button.products-list__content__item {
    width: calc((100% - 0.5rem) / 2);
  }
}
.public__products .products-list button.products-list__content__item:nth-child(3n + 3) {
  margin-right: 2rem;
}
@media (max-width: 1200px) {
  .public__products .products-list button.products-list__content__item:nth-child(3n + 3) {
    margin-right: 0.5rem;
  }
}
.public__products .products-list button.products-list__content__item:nth-child(4n + 4) {
  margin-right: 0;
}
@media (max-width: 1200px) {
  .public__products .products-list button.products-list__content__item:nth-child(4n + 4) {
    margin-right: 0.5rem;
  }
}
@media (max-width: 1200px) {
  .public__products .products-list button.products-list__content__item:nth-child(2n + 2) {
    margin-right: 0;
  }
}
.public__products .products-list .products-list__load {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fa0002;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  border: 0 !important;
  border-radius: 0 !important;
}
@media (max-width: 1200px) {
  .public__products .products-list .products-list__load {
    font-size: 0.875rem;
  }
}
@media (max-width: 1200px) {
  .public__products .products-list .products-list__load {
    width: 9.8125rem;
    height: 2.5rem;
  }
}
.public__references {
  margin-top: 11rem;
}
@media (max-width: 1200px) {
  .public__references {
    margin-top: 7rem;
  }
}
.public__references__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.public__references__header__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
  text-align: center;
}
@media (max-width: 1200px) {
  .public__references__header__title {
    font-size: 2.5rem;
  }
}
.public__references__header__button,
.public__references__header button.public__references__header__button {
  display: none;
}
.public__references__header__button svg,
.public__references__header button.public__references__header__button svg {
  width: 2rem;
  height: 2rem;
  color: #8b8b8b;
}
.public__references__header__button span,
.public__references__header button.public__references__header__button span {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-left: 0.75rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: 0.8px;
  color: #8b8b8b;
  text-transform: uppercase;
}
.public__references__content {
  position: relative;
  margin-top: 3rem;
  padding-left: 8rem;
}
@media (max-width: 1200px) {
  .public__references__content {
    margin-top: 2rem;
    padding-left: 0;
  }
}
.public__references__content .swiper {
  position: initial;
}
.public__references__content .swiper-scrollbar {
  left: 8rem !important;
  width: calc(100% - 8rem) !important;
}
.public__references__content__arrows {
  position: absolute;
  left: 0;
  top: 0;
  width: 7rem;
  height: 20rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
@media (max-width: 1200px) {
  .public__references__content__arrows {
    display: none;
  }
}
.public__references__content__arrows svg {
  width: 3rem;
  height: 3rem;
  color: #1f1f1f;
  opacity: 0.5;
  cursor: pointer;
}
.public__references__content__arrows svg:first-of-type {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.public__references__content__reference {
  margin-right: 4rem;
  min-width: 23.5rem;
  padding-bottom: 4rem;
}
@media (max-width: 1200px) {
  .public__references__content__reference {
    width: 13.25rem;
    min-width: 13.25rem;
    margin-right: 4rem;
  }
}
.public__references__content__reference__up {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 3.5rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.3;
  color: #1f1f1f;
  margin-left: 0.5rem;
}
@media (max-width: 1200px) {
  .public__references__content__reference__up {
    font-size: 2.5rem;
  }
}
.public__references__content__reference__text {
  margin-top: 2rem;
  font-style: italic;
  line-height: 1.8;
  color: #030303;
}
@media (max-width: 1200px) {
  .public__references__content__reference__text {
    font-size: 0.875rem;
    margin-top: 1.5rem;
  }
}
.public__references__content__reference__name {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-top: 1.5rem;
  font-size: 1.125rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: 0.9px;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .public__references__content__reference__name {
    font-size: 0.875rem;
  }
}
.public__references__content__reference__team {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 1.4;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .public__references__content__reference__team {
    font-size: 0.625rem;
  }
}
@media (max-width: 1200px) {
  .public__references__content__reference:last-of-type {
    margin-right: 0;
  }
}
.public__references__logos {
  margin-top: 5.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1200px) {
  .public__references__logos {
    margin-top: 1.5rem;
    flex-direction: column;
  }
}
.public__references__logos img {
  margin-right: 3rem;
  width: auto;
  height: 3rem;
  color: #8b8b8b;
}
@media (max-width: 1200px) {
  .public__references__logos img {
    margin-right: 0;
    margin-bottom: 3rem;
    height: 2rem;
  }
}
.public__references__logos img:last-of-type {
  margin-right: 0;
}
.public__references__logos-text {
  margin-top: 3rem;
  line-height: 1.8;
  color: #1f1f1f;
  text-align: center;
}
@media (max-width: 1200px) {
  .public__references__logos-text {
    margin-top: 2rem;
    font-size: 0.875rem;
  }
}
.public__blog {
  margin-top: 13.5rem;
}
@media (max-width: 1200px) {
  .public__blog {
    margin-top: 7rem;
  }
}
.public__blog__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
  text-align: center;
}
@media (max-width: 1200px) {
  .public__blog__title {
    font-size: 2.5rem;
  }
}
.public__blog .blog {
  margin-top: 5.5rem;
}

.admin .upload {
  width: 40px;
  height: 40px;
  background-color: #f5f5f5;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin .upload input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.admin .upload img {
  display: block;
  width: 100%;
}
.admin .import {
  position: relative;
}
.admin .import input {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  opacity: 0;
  cursor: pointer;
}
.admin__menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: none !important;
}
.admin__menu button {
  margin-bottom: 4px;
  background-color: #f5f5f5;
  color: #030303;
}
.admin__menu button:hover {
  background-color: #f5f5f5 !important;
}
.admin__menu button.active {
  background-color: #fa0002;
  color: #fdfdfd;
}
.admin__menu button.active:hover {
  background-color: #fa0002 !important;
}
.admin__content {
  margin-top: 40px;
}
.admin__content__edit {
  margin-bottom: 10px;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.admin__content__edit .inputs {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.admin__content__edit .inputs .input {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.admin__content__edit .inputs .input .input__label {
  margin-right: 1.5rem;
}
.admin__content__edit .inputs .input:last-of-type {
  margin-bottom: 0;
}
.admin__content__edit .input {
  flex: 1;
}
.admin__content__edit .input input {
  padding: 0 1.5rem;
  height: 50px;
  font-size: 14px !important;
}
.admin__content__edit .select .MuiSelect-select {
  flex: 1;
  font-size: 14px !important;
  background-color: #fdfdfd !important;
}
.admin__content__edit__buttons {
  display: flex;
  flex-direction: row;
}
.admin__content__edit__buttons > button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-left: 1.5rem;
  width: 150px;
}
@media (max-width: 1200px) {
  .admin__content__edit__buttons > button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
.admin__content__edit__buttons > button:last-of-type {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fa0002;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  border: 0 !important;
  border-radius: 0 !important;
  margin-left: 1.5rem;
  width: 200px;
  height: 3rem;
}
@media (max-width: 1200px) {
  .admin__content__edit__buttons > button:last-of-type {
    font-size: 0.875rem;
  }
}
.admin__content__grid {
  min-height: 70vh;
}
.MuiDataGrid-cell:focus {
  outline: none !important;
}
.translate-html-lightbox {
  width: 900px;
  min-height: 600px;
}
.translate-html-lightbox__tabs {
  width: 100%;
  background-color: #fa0002 !important;
  color: #fdfdfd !important;
  align-items: center;
}
.translate-html-lightbox__tabs .Mui-selected {
  font-weight: bold;
  color: #fdfdfd !important;
}
.translate-html-lightbox__tabs [role="tablist"] {
  justify-content: space-around;
}
.translate-html-lightbox__buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.translate-html-lightbox__buttons__button,
.translate-html-lightbox__buttons button.translate-html-lightbox__buttons__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fa0002;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  border: 0 !important;
  border-radius: 0 !important;
  width: 300px;
}
@media (max-width: 1200px) {
  .translate-html-lightbox__buttons__button,
  .translate-html-lightbox__buttons button.translate-html-lightbox__buttons__button {
    font-size: 0.875rem;
  }
}
.prices-lightbox {
  width: 400px;
  display: flex;
  flex-direction: column;
}
.prices-lightbox__price {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 1.5rem;
}
.prices-lightbox__price .input:first-of-type {
  min-width: 120px;
  max-width: 120px;
  margin-right: 1.5rem;
}
.prices-lightbox__price button {
  width: 40px;
  height: 40px;
}
.prices-lightbox__add {
  display: flex;
  flex-direction: row;
}
.prices-lightbox__add .input {
  min-width: 120px;
  max-width: 120px;
  margin-right: 1.5rem;
  margin-bottom: 0;
}
.prices-lightbox__add .button {
  flex: 1;
  height: 100%;
}
.prices-lightbox .input input {
  background-color: #fdfdfd !important;
}
.company-lightbox {
  width: 400px;
}
.company-lightbox .input {
  margin-bottom: 1.5rem;
}
.company-lightbox .input input {
  background-color: #fdfdfd !important;
}
.address-lightbox {
  width: 600px;
}
.address-lightbox .input {
  margin-bottom: 1.5rem;
}
.address-lightbox .input input {
  background-color: #fdfdfd !important;
}
.address-lightbox .select {
  margin-top: 2px;
  margin-bottom: 0;
}
.address-lightbox .select__container {
  background-color: #fdfdfd !important;
  height: 44px;
}
.address-lightbox .select__container div:first-of-type {
  padding-top: 1.5rem;
}
.address-lightbox__panels {
  display: flex;
  flex-direction: row;
}
.address-lightbox__panels__panel {
  flex: 1;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
}
.address-lightbox__panels__panel:last-of-type {
  margin-right: 0;
}

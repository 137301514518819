.checkbox svg {
  color: #8b8b8b;
}
.checkbox > span {
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .checkbox > span {
    font-size: 0.875rem;
  }
}
.MuiCheckbox-colorPrimary.Mui-checked svg {
  color: #fa0002 !important;
}

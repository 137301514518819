.results {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.results__menu {
  margin-right: 10rem;
  width: 13rem;
}
@media (max-width: 1200px) {
  .results__menu {
    width: 100%;
    margin-right: 0;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.results__menu button {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .results__menu button {
    margin-right: 1.5rem;
    width: calc((100% - 1.5rem) / 2);
    justify-content: flex-start;
  }
}
.results__menu button span {
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .results__menu button span {
    font-size: 0.875rem;
    white-space: nowrap;
  }
}
.results__menu button span.count {
  margin-left: 0.5rem;
  font-weight: 300;
  line-height: 1.19;
  color: #aaa;
}
@media (max-width: 1200px) {
  .results__menu button span.count {
    font-size: 0.875rem;
  }
}
@media (max-width: 1200px) {
  .results__menu button:nth-child(2n + 2) {
    margin-right: 0;
  }
}
.results__menu button.active span:first-of-type {
  color: #fa0002;
  font-weight: 600;
}
.results__content {
  flex: 1;
}
@media (max-width: 1200px) {
  .results__content {
    margin-top: 1.5rem;
  }
}
.results__content__input {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.results__content__input .input {
  width: 52.25rem;
}
@media (max-width: 1200px) {
  .results__content__input .input {
    width: calc(100% - 0.5rem - 2rem);
  }
}
.results__content__input svg {
  margin-bottom: 0.8rem;
  width: 2.2rem;
  height: 2.2rem;
  color: #8b8b8b;
}
.results__content__input svg:first-of-type {
  margin-left: 0.75rem;
}
@media (max-width: 1200px) {
  .results__content__input svg:first-of-type {
    margin-left: 0.5rem;
    width: 2rem;
    height: 2rem;
    margin-bottom: 0.9rem;
  }
}
.results__content__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding-top: 2rem;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .results__content__title {
    padding-top: 1.5rem;
    font-size: 1.5rem;
    line-height: 1.2;
  }
}
.results__content__title span {
  color: #8b8b8b;
}
.results__content__buttons {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .results__content__buttons {
    margin-top: 2rem;
  }
}
.results__content__buttons button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-right: 1rem;
}
@media (max-width: 1200px) {
  .results__content__buttons button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .results__content__buttons button {
    margin-right: 0.5rem;
    width: calc((100% - 0.5rem) / 2);
    min-width: auto;
  }
}
.results__content__buttons button:last-of-type {
  margin-right: 0;
}
.results__content__buttons button.active {
  box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #1f1f1f;
  color: #fefefe;
}
.results__content__data {
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  .results__content__data {
    margin-top: 1rem;
  }
}
.results__content__data button.blog__content__item {
  width: calc((100% - 2rem * 2) / 3);
}
@media (max-width: 1200px) {
  .results__content__data button.blog__content__item {
    width: 100%;
    margin-right: 0;
  }
}
.results__content__data button.blog__content__item:nth-child(4n + 4) {
  margin-right: 2rem;
}
@media (max-width: 1200px) {
  .results__content__data button.blog__content__item:nth-child(4n + 4) {
    margin-right: 0;
  }
}
.results__content__data button.blog__content__item:nth-child(3n + 3) {
  margin-right: 0;
}

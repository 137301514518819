.thanks {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1200px) {
  .thanks {
    align-items: flex-start;
  }
}
.thanks__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding-top: 4rem;
  font-size: 5rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .thanks__title {
    font-size: 3.5rem;
    padding-top: 0;
  }
}
.thanks__text1 {
  margin-top: 3rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .thanks__text1 {
    margin-top: 2rem;
    font-size: 0.875rem;
  }
}
.thanks__text1 span:nth-child(2) {
  margin: 0 0.25rem;
  font-weight: 600;
}
.thanks__text2 {
  margin-top: 1rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .thanks__text2 {
    margin-top: 1.5rem;
    font-size: 0.875rem;
  }
}
.thanks__text2 span:nth-child(2) {
  margin-left: 0.25rem;
  font-weight: 600;
}
.thanks__subtext {
  margin-top: 0.75rem;
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .thanks__subtext {
    margin-top: 1rem;
    font-size: 0.625rem;
  }
}
.thanks__order {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media (max-width: 1200px) {
  .thanks__order {
    margin-top: 3rem;
    align-items: flex-start;
  }
}
.thanks__order__payment-state {
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 52.5rem;
  margin-top: 2rem;
}
@media (max-width: 1200px) {
  .thanks__order__payment-state {
    margin-top: 0;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}
.thanks__order__payment-state__icon {
  min-width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1200px) {
  .thanks__order__payment-state__icon {
    min-width: 1rem;
    width: 1rem;
    height: 1rem;
    min-height: 1rem;
  }
}
.thanks__order__payment-state__icon svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #fff;
}
@media (max-width: 1200px) {
  .thanks__order__payment-state__icon svg {
    width: 1rem;
    height: 1rem;
  }
}
.thanks__order__payment-state__text {
  margin-left: 1rem;
  font-size: 1.5rem;
  color: #000;
  line-height: 1.4;
  text-align: center;
}
@media (max-width: 1200px) {
  .thanks__order__payment-state__text {
    font-size: 1.25rem;
    margin-left: 0.75rem;
    text-align: left;
    flex: 1;
  }
}
.thanks__order__payment-state__button {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
}
@media (max-width: 1200px) {
  .thanks__order__payment-state__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 100%;
  }
}
.thanks__order__payment-state__button button:first-of-type {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fa0002;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  border: 0 !important;
  border-radius: 0 !important;
  width: 11rem;
  height: 3rem;
}
@media (max-width: 1200px) {
  .thanks__order__payment-state__button button:first-of-type {
    font-size: 0.875rem;
  }
}
@media (max-width: 1200px) {
  .thanks__order__payment-state__button button:first-of-type {
    width: 9.8125rem;
    max-width: 9.8125rem;
    height: 2.5rem;
  }
}
.thanks__order__payment-state__button button:last-of-type {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-left: 1rem;
}
@media (max-width: 1200px) {
  .thanks__order__payment-state__button button:last-of-type {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .thanks__order__payment-state__button button:last-of-type {
    margin-left: 0;
    margin-top: 1rem;
    width: 12rem;
  }
}
.thanks__order__payment-state.success .thanks__order__payment-state__icon {
  background-color: #409b04;
}
.thanks__order__payment-state.success .thanks__order__payment-state__text {
  color: #409b04;
}
.thanks__order__payment-state.waiting .thanks__order__payment-state__icon {
  background-color: #f39a33;
}
.thanks__order__payment-state.waiting .thanks__order__payment-state__icon svg {
  width: 1.25rem;
  height: 1.25rem;
}
@media (max-width: 1200px) {
  .thanks__order__payment-state.waiting .thanks__order__payment-state__icon svg {
    width: 0.75rem;
    height: 0.75rem;
  }
}
.thanks__order__payment-state.waiting .thanks__order__payment-state__text {
  color: #f39a33;
}
.thanks__order__payment-state.error {
  max-width: 33.75rem;
  height: auto;
}
.thanks__order__payment-state.error .thanks__order__payment-state__icon {
  background-color: #c24646;
}
.thanks__order__payment-state.error .thanks__order__payment-state__text {
  color: #c24646;
}
.thanks__order__transfer {
  margin-top: 4rem;
  padding: 4rem;
  width: calc(1000px - 4rem * 2);
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .thanks__order__transfer {
    margin: 4rem -1.625rem 0 -1.625rem;
    padding: 3rem 1.625rem;
    width: 100%;
    border-radius: 0;
    flex-direction: column;
  }
}
.thanks__order__transfer__qrcode {
  width: 11.25rem;
  margin-right: 13.5rem;
}
@media (max-width: 1200px) {
  .thanks__order__transfer__qrcode {
    width: 100%;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.thanks__order__transfer__qrcode__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .thanks__order__transfer__qrcode__title {
    font-size: 1.25rem;
  }
}
.thanks__order__transfer__qrcode img {
  margin-top: 2rem;
  width: 11.25rem;
}
@media (max-width: 1200px) {
  .thanks__order__transfer__qrcode img {
    margin-top: 1rem;
    width: 9.8125rem;
  }
}
.thanks__order__transfer__info {
  flex: 1;
}
@media (max-width: 1200px) {
  .thanks__order__transfer__info {
    margin-top: 4rem;
  }
}
.thanks__order__transfer__info__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
@media (max-width: 1200px) {
  .thanks__order__transfer__info__title {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
}
.thanks__order__transfer__info__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;
}
@media (max-width: 1200px) {
  .thanks__order__transfer__info__item {
    margin-bottom: 0;
    margin-top: 1.5rem;
  }
}
.thanks__order__transfer__info__item__name {
  font-size: 1rem;
  color: #1f1f1f;
  width: 13.4375rem;
}
@media (max-width: 1200px) {
  .thanks__order__transfer__info__item__name {
    font-size: 0.875rem;
    width: 7rem;
  }
}
.thanks__order__transfer__info__item__value {
  font-weight: 500;
  color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .thanks__order__transfer__info__item__value {
    font-size: 0.875rem;
    text-align: left;
  }
}
.thanks__order__transfer__info__item__value svg {
  margin-left: 1rem;
  color: #8b8b8b;
  cursor: pointer;
}
.thanks__order__transfer__info__item:last-of-type {
  margin-bottom: 0;
}
.thanks__order__account {
  margin-top: 7rem;
  width: 46.25rem;
}
@media (max-width: 1200px) {
  .thanks__order__account {
    margin-top: 4rem;
    width: 100%;
  }
}
.thanks__order__account__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .thanks__order__account__title {
    font-size: 1.5rem;
  }
}
.thanks__order__account__panels {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .thanks__order__account__panels {
    margin-top: 1.5rem;
    flex-direction: column;
  }
}
.thanks__order__account__panels__panel {
  flex: 1;
}
.thanks__order__account__panels__panel__item {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.thanks__order__account__panels__panel__item svg {
  margin-right: 1rem;
  color: #1f1f1f;
}
.thanks__order__account__panels__panel__item span {
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .thanks__order__account__panels__panel__item span {
    font-size: 0.875rem;
  }
}
.thanks__order__account__panels__panel__item:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 1200px) {
  .thanks__order__account__panels__panel .input {
    margin-top: 4rem;
  }
}
.thanks__order__account__panels__panel__register-text {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: #1f1f1f;
}
.thanks__order__account__panels__panel__register-text a {
  color: #8b8b8b;
  text-decoration: underline;
}
.thanks__order__account__panels__panel__buttons {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
@media (max-width: 1200px) {
  .thanks__order__account__panels__panel__buttons {
    margin-top: 2rem;
  }
}
.thanks__order__account__panels__panel__buttons__button,
.thanks__order__account__panels__panel__buttons button.thanks__order__account__panels__panel__buttons__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
}
@media (max-width: 1200px) {
  .thanks__order__account__panels__panel__buttons__button,
  .thanks__order__account__panels__panel__buttons button.thanks__order__account__panels__panel__buttons__button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
.thanks__order__account__panels__panel:first-of-type {
  margin-right: 2rem;
}
@media (max-width: 1200px) {
  .thanks__order__account__panels__panel:first-of-type {
    margin-right: 0;
  }
}
.thanks__order__data {
  margin-top: 5.5rem;
  padding: 4rem;
  background-color: #f7f7f7;
  width: calc(100% - 4rem * 2);
}
@media (max-width: 1200px) {
  .thanks__order__data {
    margin: 4rem -1.625rem 0 -1.625rem;
    padding: 3rem 1.625rem;
    width: 100%;
    border-radius: 0;
  }
}
.thanks__order__data__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .thanks__order__data__title {
    font-size: 1.5rem;
  }
}
.thanks__order__data__info {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .thanks__order__data__info {
    margin-top: 1.5rem;
    flex-wrap: wrap;
  }
}
.thanks__order__data__info__panel {
  margin-right: 2rem;
}
@media (max-width: 1200px) {
  .thanks__order__data__info__panel {
    margin-right: 0;
  }
}
.thanks__order__data__info__panel__item {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.thanks__order__data__info__panel__item__name {
  color: #1f1f1f;
  width: 15.625rem;
}
@media (max-width: 1200px) {
  .thanks__order__data__info__panel__item__name {
    width: 50%;
    font-size: 0.875rem;
  }
}
.thanks__order__data__info__panel__item__value {
  font-weight: 600;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .thanks__order__data__info__panel__item__value {
    font-size: 0.875rem;
  }
}
.thanks__order__data__info__panel__item:last-of-type {
  margin-bottom: 0;
}
.thanks__order__data__info__panel__address__title {
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #161616;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
.thanks__order__data__info__panel__address__line {
  margin-bottom: 1rem;
  color: #1f1f1f;
  line-height: 2;
}
@media (max-width: 1200px) {
  .thanks__order__data__info__panel__address__line {
    font-size: 0.875rem;
  }
}
.thanks__order__data__info__panel__address__line:last-of-type {
  margin-bottom: 0;
}
.thanks__order__data__info__panel:first-of-type {
  flex: 1;
}
@media (max-width: 1200px) {
  .thanks__order__data__info__panel:first-of-type {
    min-width: 100%;
    width: 100%;
    margin-bottom: 5.5rem;
  }
}
.thanks__order__data__info__panel:nth-child(2) {
  width: 21.875rem;
}
@media (max-width: 1200px) {
  .thanks__order__data__info__panel:nth-child(2) {
    width: 50%;
  }
}
.thanks__order__data__info__panel:nth-child(3) {
  width: 21.875rem;
  margin-right: 0;
}
@media (max-width: 1200px) {
  .thanks__order__data__info__panel:nth-child(3) {
    width: 50%;
  }
}
.thanks__order__data__down {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .thanks__order__data__down {
    flex-direction: column;
  }
}
.thanks__order__data__down__products {
  flex: 1;
  padding: 4rem;
  background-color: #fefefe;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__products {
    padding: 1.5rem 1rem;
    overflow-x: scroll;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: -1.625rem;
  }
}
.thanks__order__data__down__products__product {
  margin-top: 2rem;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__products__product {
    width: 40rem;
  }
}
.thanks__order__data__down__products__product__up {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 2.5rem;
}
.thanks__order__data__down__products__product__up__left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.thanks__order__data__down__products__product__up__left__photo {
  width: 2.5rem;
  height: 2.5rem;
}
.thanks__order__data__down__products__product__up__left__photo img {
  width: 100%;
  mix-blend-mode: multiply;
}
.thanks__order__data__down__products__product__up__info {
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__products__product__up__info {
    padding: 0 0.5rem;
  }
}
.thanks__order__data__down__products__product__up__info__name {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.9px;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__products__product__up__info__name {
    font-size: 0.875rem;
  }
}
.thanks__order__data__down__products__product__up__stock {
  width: 6.25rem;
  text-align: right;
  white-space: nowrap;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__products__product__up__stock {
    font-size: 0.875rem;
    width: 6rem;
  }
}
.thanks__order__data__down__products__product__up__stock.instock {
  color: #409b04;
}
.thanks__order__data__down__products__product__up__stock.order {
  color: #f39a33;
}
.thanks__order__data__down__products__product__up__count {
  width: 8rem;
  text-align: right;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__products__product__up__count {
    font-size: 0.875rem;
    width: 4rem;
  }
}
.thanks__order__data__down__products__product__up__unitprice {
  width: 8rem;
  text-align: right;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__products__product__up__unitprice {
    font-size: 0.875rem;
    width: 8rem;
  }
}
.thanks__order__data__down__products__product__up__price {
  color: #1f1f1f;
  font-size: 1rem;
  text-align: right;
  width: 9.5rem;
  font-weight: 600;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__products__product__up__price {
    font-size: 0.875rem;
    width: 8rem;
  }
}
.thanks__order__data__down__products__product__up.with-extensions {
  align-items: flex-start;
}
.thanks__order__data__down__products__product__extensions__extension {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 5.5rem;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__products__product__extensions__extension {
    padding-left: 3rem;
  }
}
.thanks__order__data__down__products__product__extensions__extension__name {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__products__product__extensions__extension__name {
    font-size: 0.625rem;
  }
}
.thanks__order__data__down__products__product__extensions__extension__name svg {
  margin-left: 0.25rem;
  width: 1rem;
  height: 1rem;
  color: #8b8b8b;
}
.thanks__order__data__down__products__product__extensions__extension__price {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__products__product__extensions__extension__price {
    font-size: 0.625rem;
  }
}
.thanks__order__data__down__products__product__extensions__extension:first-of-type {
  margin-top: 0;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__products__product__extensions__extension:first-of-type {
    margin-top: -0.75rem;
  }
}
.thanks__order__data__down__products__product:first-of-type {
  margin-top: 0;
}
.thanks__order__data__down__summary {
  width: 21.875rem;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__summary {
    width: 100%;
    margin-left: 0;
    margin-top: 5.5rem;
  }
}
.thanks__order__data__down__summary__amounts {
  width: 100%;
}
.thanks__order__data__down__summary__amounts__item {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.thanks__order__data__down__summary__amounts__item__name {
  font-size: 1rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__summary__amounts__item__name {
    font-size: 0.875rem;
  }
}
.thanks__order__data__down__summary__amounts__item__value {
  font-size: 1rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__summary__amounts__item__value {
    font-size: 0.875rem;
  }
}
.thanks__order__data__down__summary__amounts__item:last-of-type {
  margin-bottom: 0;
}
.thanks__order__data__down__summary__amount {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.thanks__order__data__down__summary__amount__name {
  font-size: 1.5rem;
  color: #000;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__summary__amount__name {
    font-size: 1.25rem;
  }
}
.thanks__order__data__down__summary__amount__value {
  font-size: 1.375rem;
  color: #000;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__summary__amount__value {
    font-size: 1.25rem;
  }
}
.thanks__order__data__down__summary__vat {
  margin-top: 1rem;
  width: 100%;
}
.thanks__order__data__down__summary__vat__item {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.thanks__order__data__down__summary__vat__item__name {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__summary__vat__item__name {
    font-size: 0.625rem;
  }
}
.thanks__order__data__down__summary__vat__item__value {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .thanks__order__data__down__summary__vat__item__value {
    font-size: 0.625rem;
  }
}
.thanks__order__data__down__summary__vat__item:last-of-type {
  margin-bottom: 0;
}
.thanks .newsletter {
  margin-top: 5.5rem;
}
@media (max-width: 1200px) {
  .thanks .newsletter {
    margin-top: 4rem;
  }
}

.delivery-payment__table {
  margin-top: 9rem;
}
@media (max-width: 1200px) {
  .delivery-payment__table {
    margin-top: 5.5rem;
  }
}
.delivery-payment__table__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 3.5rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
  padding-bottom: 1.5rem;
}
@media (max-width: 1200px) {
  .delivery-payment__table__title {
    font-size: 2.5rem;
  }
}
.delivery-payment__table__rows {
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@media (max-width: 1200px) {
  .delivery-payment__table__rows {
    overflow-x: scroll;
  }
}
.delivery-payment__table__rows__row {
  height: 4.375rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #8b8b8b;
}
@media (max-width: 1200px) {
  .delivery-payment__table__rows__row {
    height: auto;
    border-bottom: 0;
  }
}
.delivery-payment__table__rows__row__column {
  font-size: 1rem;
  color: #1f1f1f;
  flex: 1;
}
@media (max-width: 1200px) {
  .delivery-payment__table__rows__row__column {
    width: auto;
    min-width: 12.5rem;
    border-bottom: 1px solid #8b8b8b;
    padding: 1rem 0;
    font-size: 0.875rem;
    position: relative;
    height: 2rem;
    line-height: 2rem;
  }
}
.delivery-payment__table__rows__row__column img {
  height: 2.4rem;
  border-radius: 0.5rem;
}
.delivery-payment__table__rows__row:first-of-type .delivery-payment__table__rows__row__column {
  font-weight: 600;
}
.delivery-payment__table__rows__row:last-of-type {
  border-bottom: 0;
}
.delivery-payment__table.payments {
  width: 54rem;
}
@media (max-width: 1200px) {
  .delivery-payment__table.payments {
    width: auto;
    margin-bottom: 5.5rem;
  }
}

@import 'variables';

.delivery-payment {
    &__table {
        margin-top: @spacing5XL;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacing3XL;
        }

        &__title {
            .fontBarlow();

            font-size: 3.5rem;
            font-weight: bold;
            font-style: italic;
            color: #1f1f1f;
            text-transform: uppercase;
            padding-bottom: @spacingM;

            @media (max-width: @mobileBreakpoint) {
                font-size: 2.5rem;
            }
        }

        &__rows {
            margin-top: @spacingM;
            padding-bottom: @spacingM;

            @media (max-width: @mobileBreakpoint) {
                overflow-x: scroll;
            }

            &__row {
                height: 4.375rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 1px solid @colorNeutral2;

                @media (max-width: @mobileBreakpoint) {
                    height: auto;
                    border-bottom: 0;
                }

                &__column {
                    font-size: 1rem;
                    color: #1f1f1f;
                    flex: 1;

                    @media (max-width: @mobileBreakpoint) {
                        width: auto;
                        min-width: 12.5rem;
                        border-bottom: 1px solid @colorNeutral2;
                        padding: @spacingS 0;
                        font-size: 0.875rem;
                        position: relative;
                        height: 2rem;
                        line-height: 2rem;
                    }

                    img {
                        height: 2.4rem;
                        border-radius: 0.5rem;
                    }
                }
            }
            &__row:first-of-type {
                .delivery-payment__table__rows__row__column {
                    font-weight: 600;
                }
            }
            &__row:last-of-type {
                border-bottom: 0;
            }
        }
    }
    &__table.payments {
        width: 54rem;

        @media (max-width: @mobileBreakpoint) {
            width: auto;
            margin-bottom: @spacing3XL;
        }
    }
}

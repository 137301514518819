.product {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
}
@media (max-width: 1200px) {
  .product .delivery-payment {
    width: 100%;
  }
}
.product .delivery-payment .delivery-payment__table {
  margin-top: 5.5rem;
  width: 100%;
}
@media (max-width: 1200px) {
  .product .delivery-payment .delivery-payment__table {
    margin-top: 3rem;
  }
}
.product .delivery-payment .delivery-payment__table .delivery-payment__table__title {
  font-size: 2rem;
}
@media (max-width: 1200px) {
  .product .delivery-payment .delivery-payment__table .delivery-payment__table__title {
    font-size: 1.5rem;
  }
}
.product .delivery-payment .delivery-payment__table .delivery-payment__table__rows {
  margin-top: 0;
}
.product .delivery-payment .delivery-payment__table:not(.payments) .delivery-payment__table__rows__row__column:nth-child(1),
.product .delivery-payment .delivery-payment__table:not(.payments) .delivery-payment__table__rows__row__column:nth-child(3) {
  display: none;
}
@media (max-width: 1200px) {
  .product .delivery-payment .delivery-payment__table.payments {
    margin-bottom: 0;
  }
}
@media (max-width: 1200px) {
  .product {
    flex-direction: column;
  }
}
.product__left {
  margin-right: 8rem;
  width: 48rem;
}
@media (max-width: 1200px) {
  .product__left {
    width: 100%;
    margin-right: 0;
    margin-top: 0.5rem;
  }
}
.product__left__slideshow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.product__left__slideshow__photos {
  width: 6rem;
}
@media (max-width: 1200px) {
  .product__left__slideshow__photos {
    display: none;
  }
}
.product__left__slideshow__photos__photo,
.product__left__slideshow__photos button.product__left__slideshow__photos__photo {
  margin-bottom: 2rem;
  width: 6rem;
  height: 7.75rem;
  background-color: #f5f5f5;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product__left__slideshow__photos__photo img,
.product__left__slideshow__photos button.product__left__slideshow__photos__photo img {
  height: 100%;
  mix-blend-mode: multiply;
}
.product__left__slideshow__photo {
  position: relative;
  width: 40rem;
  height: 40rem;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .product__left__slideshow__photo {
    width: 100%;
    height: 32rem;
    border-radius: 0;
  }
}
.product__left__slideshow__photo img {
  height: 100%;
  mix-blend-mode: multiply;
}
.product__left__slideshow__photo button {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 3.75rem;
  height: 3.75rem;
}
@media (max-width: 1200px) {
  .product__left__slideshow__photo button {
    background-color: #fdfdfd;
    border-radius: 100%;
    width: 2.5rem;
    height: 2.5rem;
  }
}
.product__left__slideshow__photo button svg {
  width: 5rem;
  height: 5rem;
  opacity: 0.5;
}
@media (max-width: 1200px) {
  .product__left__slideshow__photo button svg {
    width: 3rem;
    height: 3rem;
  }
}
.product__left__slideshow__photo button.prev {
  left: 0.5rem;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.product__left__slideshow__photo button.next {
  right: 0.5rem;
}
.product__left__sections {
  display: flex;
  flex-direction: row;
  margin-top: 7rem;
}
@media (max-width: 1200px) {
  .product__left__sections {
    margin-top: 5.5rem;
    flex-wrap: wrap;
  }
}
.product__left__sections button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-right: 1rem;
}
@media (max-width: 1200px) {
  .product__left__sections button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .product__left__sections button {
    width: calc((100% - 1rem) / 2);
    min-width: calc((100% - 1rem) / 2);
    margin-bottom: 1rem;
  }
}
@media (max-width: 1200px) {
  .product__left__sections button:nth-child(2n + 2) {
    margin-right: 0;
  }
}
.product__left__sections button.active {
  box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #1f1f1f;
  color: #fefefe;
}
.product__left__description {
  margin-top: 5.5rem;
  line-height: 1.8;
}
@media (max-width: 1200px) {
  .product__left__description {
    margin-top: 3rem;
  }
}
.product__left__reviews {
  margin-top: 5.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product__left__reviews__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.4;
  color: #1f1f1f;
  text-transform: uppercase;
  width: 100%;
}
@media (max-width: 1200px) {
  .product__left__reviews__title {
    font-size: 1.5rem;
  }
}
.product__left__reviews__stats {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
@media (max-width: 1200px) {
  .product__left__reviews__stats {
    margin-top: 2rem;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
@media (max-width: 1200px) {
  .product__left__reviews__stats__left {
    margin-top: 3rem;
    width: 100%;
  }
}
.product__left__reviews__stats__left__line {
  height: 0.75rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.product__left__reviews__stats__left__line svg {
  width: 1rem;
  height: 1rem;
}
.product__left__reviews__stats__left__line__loader {
  position: relative;
  margin-left: 1rem;
  width: 13.875rem;
  height: 0.5rem;
  background-color: #f7f7f7;
  border-radius: 0.5rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .product__left__reviews__stats__left__line__loader {
    width: auto;
    flex: 1;
  }
}
.product__left__reviews__stats__left__line__loader__in {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #1f1f1f;
}
.product__left__reviews__stats__left__line__value {
  margin-left: 1.5rem;
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .product__left__reviews__stats__left__line__value {
    margin-left: 2rem;
  }
}
.product__left__reviews__stats__left__text {
  margin-top: 1.5rem;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .product__left__reviews__stats__left__text {
    font-size: 0.875rem;
  }
}
.product__left__reviews__stats__right {
  margin-left: 10rem;
}
@media (max-width: 1200px) {
  .product__left__reviews__stats__right {
    margin-left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }
}
.product__left__reviews__stats__right__value {
  margin-bottom: 1rem;
  font-size: 1.625rem;
  font-weight: bold;
  line-height: 1.38;
  color: #000;
}
@media (max-width: 1200px) {
  .product__left__reviews__stats__right__value {
    width: 100%;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }
}
.product__left__reviews__stats__right button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-top: 2rem;
}
@media (max-width: 1200px) {
  .product__left__reviews__stats__right button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .product__left__reviews__stats__right button {
    margin-top: 0;
  }
}
.product__left__reviews__stats__right button[disabled] {
  background-color: transparent !important;
  opacity: 0.5;
}
.product__left__reviews__items {
  display: flex;
  flex-direction: row;
  margin-top: 7rem;
  width: 100%;
}
@media (max-width: 1200px) {
  .product__left__reviews__items {
    margin-top: 3rem;
    flex-direction: column;
  }
}
.product__left__reviews__items__panel {
  flex: 1;
}
.product__left__reviews__items__panel__item {
  margin-bottom: 3rem;
}
@media (max-width: 1200px) {
  .product__left__reviews__items__panel__item {
    margin-bottom: 2rem;
  }
}
.product__left__reviews__items__panel__item__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.product__left__reviews__items__panel__item__header__name {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: 0.9px;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .product__left__reviews__items__panel__item__header__name {
    font-size: 0.875rem;
  }
}
.product__left__reviews__items__panel__item__header__date {
  font-size: 0.75rem;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: right;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .product__left__reviews__items__panel__item__header__date {
    font-size: 0.625rem;
  }
}
.product__left__reviews__items__panel__item svg {
  width: 1rem;
  height: 1rem;
}
.product__left__reviews__items__panel__item__text {
  margin-top: 1rem;
  padding-right: 3rem;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .product__left__reviews__items__panel__item__text {
    font-size: 0.875rem;
    padding-right: 0;
    margin-top: 0.75rem;
  }
}
.product__left__reviews__items__panel__item > img {
  margin-top: 1.5rem;
  width: 100%;
}
.product__left__reviews__items__panel:first-of-type {
  margin-right: 2rem;
}
@media (max-width: 1200px) {
  .product__left__reviews__items__panel:first-of-type {
    margin-right: 0;
  }
}
.product__left__reviews > button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  white-space: nowrap;
}
@media (max-width: 1200px) {
  .product__left__reviews > button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
.product__right {
  position: sticky;
  width: 30.3125rem;
  top: 12.5rem;
  height: auto;
}
@media (max-width: 1200px) {
  .product__right {
    position: relative;
    width: 100%;
    top: 0;
    margin-top: 1.5rem;
  }
}
.product__right__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .product__right__title {
    font-size: 1.5rem;
  }
}
.product__right__price {
  margin-top: 1rem;
  font-size: 1.5rem;
  color: #000;
}
@media (max-width: 1200px) {
  .product__right__price {
    font-size: 1.25rem;
  }
}
.product__right__info {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .product__right__info {
    flex-direction: column;
    margin-top: 2rem;
    align-items: flex-start;
  }
}
.product__right__info__variables {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 70%;
}
.product__right__info__variables > div {
  font-size: 0.75rem;
  background-color: #f5f5f5;
  color: #1f1f1f;
  height: 1.5rem !important;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 0 !important;
}
.product__right__info__variables > div span {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.product__right__info__rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1rem;
}
@media (max-width: 1200px) {
  .product__right__info__rating {
    margin-top: 0.75rem;
  }
}
.product__right__info__rating svg {
  width: 1.25rem;
  height: 1.25rem;
}
@media (max-width: 1200px) {
  .product__right__info__rating svg {
    width: 1rem;
    height: 1rem;
  }
}
.product__right__info__rating__text {
  margin-left: 0.5rem;
  font-size: 0.75rem;
  color: #1f1f1f;
  white-space: nowrap;
}
@media (max-width: 1200px) {
  .product__right__info__rating__text {
    font-size: 0.625rem;
  }
}
.product__right__variants {
  margin-top: 3rem;
}
.product__right__variants__title {
  font-size: 0.875rem;
  color: #1f1f1f;
}
.product__right__variants__title.order {
  color: #f39a33;
}
.product__right__variants__title.instock {
  color: #409b04;
}
.product__right__variants__items {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .product__right__variants__items {
    flex-wrap: wrap;
    margin-top: 0;
  }
}
.product__right__variants__items > span {
  margin-right: 0.5rem;
}
@media (max-width: 1200px) {
  .product__right__variants__items > span {
    margin-top: 0.5rem;
  }
}
.product__right__variants__items__variant,
.product__right__variants__items button.product__right__variants__items__variant {
  width: 3rem;
  height: 3rem;
  border: solid 1px #409b04;
  font-size: 0.875rem;
  color: #409b04;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product__right__variants__items__variant[disabled],
.product__right__variants__items button.product__right__variants__items__variant[disabled] {
  border-color: #f7f7f7;
  color: #e1e1e1;
  background: linear-gradient(to top right, #f7f7f7 0%, #f7f7f7 calc(50% - 0.8px), #e1e1e1 50%, #f7f7f7 calc(50% + 0.8px), #f7f7f7 100%);
}
.product__right__variants__items__variant.order,
.product__right__variants__items button.product__right__variants__items__variant.order {
  border-color: #f39a33;
  color: #f39a33;
}
.product__right__variants__items__variant.instock,
.product__right__variants__items button.product__right__variants__items__variant.instock {
  border-color: #409b04;
  color: #409b04;
}
.product__right__variants__items__variant.active,
.product__right__variants__items button.product__right__variants__items__variant.active {
  border-color: #fa0002;
  background-color: #fa0002;
  color: #f5f5f5;
  font-weight: 600;
}
.product__right__variants__items__variant:last-of-type,
.product__right__variants__items button.product__right__variants__items__variant:last-of-type {
  margin-right: 0;
}
.product__right__table-size,
.product__right button.product__right__table-size {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.875rem;
  color: #8b8b8b;
}
.product__right__table-size svg,
.product__right button.product__right__table-size svg {
  margin-right: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.product__right__extensions {
  padding: 1.5rem;
  margin-top: 3rem;
  background-color: #f5f5f5;
}
.product__right__extensions__item {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 1.25rem;
}
@media (max-width: 1200px) {
  .product__right__extensions__item {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.product__right__extensions__item .checkbox > span {
  font-size: 0.875rem;
  color: #1f1f1f;
}
.product__right__extensions__item__price {
  color: #fa0002;
  font-size: 0.875rem;
}
@media (max-width: 1200px) {
  .product__right__extensions__item__price {
    margin-left: 2rem;
  }
}
.product__right__extensions__item:last-of-type {
  margin-bottom: 0;
}
.product__right__buy {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .product__right__buy {
    margin-top: 3rem;
  }
}
.product__right__buy__counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 2rem;
}
.product__right__buy__counter__button,
.product__right__buy__counter button.product__right__buy__counter__button {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 100%;
  background-color: #f5f5f5;
}
.product__right__buy__counter__button svg,
.product__right__buy__counter button.product__right__buy__counter__button svg {
  width: 1.125rem;
  height: 1.125rem;
  color: #8b8b8b;
}
.product__right__buy__counter__value {
  width: 1.875rem;
  font-size: 1rem;
  text-align: center;
  color: #1f1f1f;
}
.product__right__buy__button,
.product__right__buy button.product__right__buy__button {
  flex: 1;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fa0002;
}
@media (max-width: 1200px) {
  .product__right__buy__button,
  .product__right__buy button.product__right__buy__button {
    height: 2.5rem;
  }
}
.product__right__buy__button svg,
.product__right__buy button.product__right__buy__button svg {
  margin-right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
}
.product__right__buy__button span,
.product__right__buy button.product__right__buy__button span {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.product__right__buy-fixed {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: calc(100% - 1.5rem * 2);
  height: 2.5rem;
  box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: #f7f7f7;
  padding: 1.5rem;
  z-index: 100;
}
.product__right__buy-fixed .product__right__buy {
  margin-top: 0;
}
@media (max-width: 1200px) {
  .product__right__buy-fixed {
    display: block;
  }
}
.product__right__buttons {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 1200px) {
  .product__right__buttons {
    flex-direction: column;
    margin-top: 3rem;
  }
}
.product__right__buttons button {
  margin-bottom: 0.5rem;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 1200px) {
  .product__right__buttons button {
    width: 100%;
  }
}
.product__right__buttons button svg {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #8b8b8b;
}
@media (max-width: 1200px) {
  .product__right__buttons button svg {
    margin-right: 1.5rem;
  }
}
.product__right__buttons button span {
  font-size: 0.75rem;
  color: #8b8b8b;
  text-transform: uppercase;
}
.product__right__buttons button.active svg,
.product__right__buttons button.active span {
  color: #c24646;
}
.product__right__short-description {
  margin-top: 4rem;
  line-height: 1.8;
}
.product__related {
  margin-top: 7rem;
  margin-right: 8rem;
  width: 48rem;
}
@media (max-width: 1200px) {
  .product__related {
    margin-right: 0;
    width: 100%;
  }
}
.product__related__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .product__related__title {
    font-size: 2.5rem;
    max-width: 50%;
    text-align: left;
  }
}
.product__related .products-list {
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  .product__related .products-list {
    margin-top: 0;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
@media (max-width: 1200px) {
  .product__related .products-list .products-list__content {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
.product__related .products-list button.products-list__content__item {
  width: calc((100% - 0.5rem) / 2);
  margin-right: 0.5rem !important;
}
.product__related .products-list button.products-list__content__item:nth-child(2n + 2) {
  margin-right: 0 !important;
}
@media (max-width: 1200px) {
  .product__related .products-list .products-list__load {
    display: none;
  }
}
.extension-lightbox {
  width: 46rem;
}
@media (max-width: 1200px) {
  .extension-lightbox {
    width: 100%;
  }
}
.extension-lightbox__text {
  margin: auto;
  width: 38rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .extension-lightbox__text {
    width: 100%;
    text-align: left;
    font-size: 0.875rem;
  }
}
.extension-lightbox__inputs {
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  .extension-lightbox__inputs {
    margin-top: 2rem;
    flex-direction: column;
  }
}
.extension-lightbox__inputs .input {
  flex: 1;
}
.extension-lightbox__inputs .input:first-of-type {
  margin-right: 2rem;
}
@media (max-width: 1200px) {
  .extension-lightbox__inputs .input:first-of-type {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}
.extension-lightbox__colors {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background-color: #f7f7f7;
  padding: 0 3rem 2rem 3rem;
}
@media (max-width: 1200px) {
  .extension-lightbox__colors {
    margin-top: 3rem;
    padding-left: 2.5rem;
    padding-right: 0.5rem;
    justify-content: center;
  }
}
.extension-lightbox__colors__color {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-right: 2rem;
  width: 5rem;
}
.extension-lightbox__colors__color__code,
.extension-lightbox__colors__color > button {
  width: 5rem;
  height: 5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
}
.extension-lightbox__colors__color__code svg,
.extension-lightbox__colors__color > button svg {
  color: #fdfdfd;
}
.extension-lightbox__colors__color__name {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 1.4;
  text-align: left;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .extension-lightbox__colors__color__name {
    font-size: 0.625rem;
  }
}
.extension-lightbox__colors__color:nth-child(6n + 6) {
  margin-right: 0;
}
@media (max-width: 1200px) {
  .extension-lightbox__colors__color:nth-child(6n + 6) {
    margin-right: 2rem;
  }
}
.extension-lightbox__colors__color.ffffff svg {
  color: black;
}
.extension-lightbox__buttons {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.extension-lightbox__buttons button {
  width: 11rem !important;
  height: 3rem !important;
  padding: 0 !important;
}
.extension-lightbox__buttons button:first-of-type {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
  margin-right: 1rem;
}
@media (max-width: 1200px) {
  .extension-lightbox__buttons button:first-of-type {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
.extension-lightbox__buttons button:last-of-type {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fa0002;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  border: 0 !important;
  border-radius: 0 !important;
}
@media (max-width: 1200px) {
  .extension-lightbox__buttons button:last-of-type {
    font-size: 0.875rem;
  }
}
.product-add-to-list-lightbox {
  width: 26rem;
}
@media (max-width: 1200px) {
  .product-add-to-list-lightbox {
    width: 100%;
  }
}
.review-lightbox {
  width: 46rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1200px) {
  .review-lightbox {
    width: 100%;
  }
}
.review-lightbox__text {
  margin: auto;
  width: 30rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .review-lightbox__text {
    width: 100%;
    font-size: 0.875rem;
  }
}
.review-lightbox__text span {
  font-size: 1.375rem;
  font-weight: 300;
  line-height: 1.27;
  color: #8b8b8b;
}
.review-lightbox__review-text {
  margin-top: 2.5rem;
  line-height: 1.8;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .review-lightbox__review-text {
    font-size: 0.875rem;
  }
}
.review-lightbox__review-rating {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}
.review-lightbox__review-rating svg {
  width: 3rem;
  height: 3rem;
}
.review-lightbox .input__label span {
  font-size: 1.375rem;
  font-weight: 300;
  line-height: 1.27;
  color: #8b8b8b;
}
.review-lightbox__upload {
  margin-top: 1.5rem;
  width: 100%;
}
.review-lightbox__upload__label {
  font-size: 0.75rem;
  line-height: 1.4;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .review-lightbox__upload__label {
    font-size: 0.625rem;
  }
}
.review-lightbox__upload__button {
  position: relative;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .review-lightbox__upload__button {
    justify-content: flex-end;
  }
}
.review-lightbox__upload__button button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
}
@media (max-width: 1200px) {
  .review-lightbox__upload__button button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
.review-lightbox__upload__button__text {
  margin-left: 1rem;
  font-size: 0.75rem;
  line-height: 1.4;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .review-lightbox__upload__button__text {
    display: none;
  }
}
.review-lightbox__upload__button input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.review-lightbox__inputs {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  width: 100%;
}
.review-lightbox__inputs .input {
  flex: 1;
}
.review-lightbox__inputs .input:first-of-type {
  margin-right: 2rem;
}
.review-lightbox__buttons {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (max-width: 1200px) {
  .review-lightbox__buttons {
    flex-direction: column;
    margin-top: 2rem;
  }
}
.review-lightbox__buttons button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fa0002;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  border: 0 !important;
  border-radius: 0 !important;
  width: 11rem !important;
  height: 3rem !important;
  padding: 0 !important;
}
@media (max-width: 1200px) {
  .review-lightbox__buttons button {
    font-size: 0.875rem;
  }
}
@media (max-width: 1200px) {
  .review-lightbox__buttons button {
    margin-top: 1.5rem;
  }
}
.review-lightbox__buttons .checkbox span {
  font-size: 0.75rem;
  line-height: 2.4;
  color: #1f1f1f;
}
.review-lightbox__buttons .checkbox span a {
  color: #8b8b8b;
  text-decoration: underline;
}

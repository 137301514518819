.checkout {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .checkout {
    flex-direction: column;
  }
}
.checkout .input__left {
  top: auto;
  bottom: 0;
}
.checkout .phone {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.checkout .phone .select {
  margin-top: -3px;
  width: 7.25rem;
}
@media (max-width: 1200px) {
  .checkout .phone .select {
    margin-top: 0;
  }
}
.checkout .phone .select .select__label {
  opacity: 0;
}
.checkout .phone .select .select__container {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.checkout .phone .input .input__label {
  margin-left: -7.25rem;
}
.checkout .phone .input .MuiFilledInput-root {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.checkout textarea {
  margin-top: -0.625rem;
}
.checkout__settings {
  flex: 1;
}
.checkout__settings__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  font-weight: bold;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .checkout__settings__title {
    font-size: 3.5rem;
  }
}
.checkout__settings__account {
  margin-top: 1rem;
}
.checkout__settings__account__header {
  height: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .checkout__settings__account__header {
    justify-content: space-between;
    height: auto;
  }
}
.checkout__settings__account__header__text {
  font-weight: 300;
  color: #000;
}
@media (max-width: 1200px) {
  .checkout__settings__account__header__text {
    font-size: 0.875rem;
  }
}
.checkout__settings__account__header__button,
.checkout__settings__account__header button.checkout__settings__account__header__button {
  margin-left: 4rem;
  font-weight: 300;
  color: #8b8b8b;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .checkout__settings__account__header__button,
  .checkout__settings__account__header button.checkout__settings__account__header__button {
    font-size: 0.875rem;
    margin-left: 0;
  }
}
.checkout__settings__account__content {
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  .checkout__settings__account__content {
    margin-top: 3rem;
  }
}
.checkout__settings__account__content__inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .checkout__settings__account__content__inputs {
    flex-direction: column;
  }
}
.checkout__settings__account__content__inputs .input {
  flex: 1;
}
.checkout__settings__account__content__inputs .input:first-of-type {
  margin-right: 2rem;
}
@media (max-width: 1200px) {
  .checkout__settings__account__content__inputs .input:first-of-type {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}
.checkout__settings__account__content__lost-password {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.checkout__settings__account__content__lost-password__button,
.checkout__settings__account__content__lost-password button.checkout__settings__account__content__lost-password__button {
  margin-left: 4rem;
  font-weight: 300;
  color: #8b8b8b;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .checkout__settings__account__content__lost-password__button,
  .checkout__settings__account__content__lost-password button.checkout__settings__account__content__lost-password__button {
    font-size: 0.875rem;
  }
}
.checkout__settings__account__content__buttons {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 1200px) {
  .checkout__settings__account__content__buttons {
    flex-direction: column-reverse;
  }
}
.checkout__settings__account__content__buttons__button,
.checkout__settings__account__content__buttons button.checkout__settings__account__content__buttons__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
}
@media (max-width: 1200px) {
  .checkout__settings__account__content__buttons__button,
  .checkout__settings__account__content__buttons button.checkout__settings__account__content__buttons__button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .checkout__settings__account__content__buttons__button,
  .checkout__settings__account__content__buttons button.checkout__settings__account__content__buttons__button {
    margin-bottom: 1rem;
  }
}
.checkout__settings__contact {
  margin-top: 3rem;
}
@media (max-width: 1200px) {
  .checkout__settings__contact {
    margin-top: 4rem;
  }
}
.checkout__settings__contact__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
  padding-bottom: 1.5rem;
}
@media (max-width: 1200px) {
  .checkout__settings__contact__title {
    font-size: 1.5rem;
  }
}
.checkout__settings__contact__inputs {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .checkout__settings__contact__inputs {
    margin-top: 0;
    flex-direction: column;
  }
}
.checkout__settings__contact__inputs .input {
  flex: 1;
}
@media (max-width: 1200px) {
  .checkout__settings__contact__inputs .input {
    margin-top: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .checkout__settings__contact__inputs .select {
    margin-top: 1.5rem !important;
  }
}
.checkout__settings__contact__inputs .input:first-of-type {
  margin-right: 2rem;
  max-width: 21.25rem;
}
@media (max-width: 1200px) {
  .checkout__settings__contact__inputs .input:first-of-type {
    margin-right: 0;
    max-width: 100%;
  }
}
.checkout__settings__contact__inputs > .select:first-of-type {
  margin-right: 2rem;
  max-width: 21.25rem;
}
@media (max-width: 1200px) {
  .checkout__settings__contact__inputs > .select:first-of-type {
    margin-right: 0;
    max-width: 100%;
  }
}
.checkout__settings__contact__create-account,
.checkout__settings__contact button.checkout__settings__contact__create-account {
  margin-top: 0.5rem;
  font-weight: 300;
  color: #8b8b8b;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .checkout__settings__contact__create-account,
  .checkout__settings__contact button.checkout__settings__contact__create-account {
    font-size: 0.875rem;
  }
}
.checkout__settings__contact > .input {
  margin-top: 2rem;
  width: calc((100% - 2rem) / 2);
}
@media (max-width: 1200px) {
  .checkout__settings__contact > .input {
    width: 100%;
    margin-top: 1.5rem;
  }
}
.checkout__settings__address {
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  .checkout__settings__address {
    margin-top: 5.5rem;
  }
}
.checkout__settings__address__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .checkout__settings__address__title {
    font-size: 1.5rem;
  }
}
.checkout__settings__address > .checkbox {
  margin-top: 1.5rem;
  margin-bottom: -0.5rem;
}
@media (max-width: 1200px) {
  .checkout__settings__address > .checkbox {
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
.checkout__settings__address__subtitle {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #1f1f1f;
  text-transform: uppercase;
  padding-top: 3rem;
}
@media (max-width: 1200px) {
  .checkout__settings__address__subtitle {
    font-size: 1.25rem;
  }
}
.checkout__settings__address__inputs {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checkout__settings__address__inputs .input {
  flex: 1;
}
.checkout__settings__address__inputs .input:first-of-type {
  margin-right: 2rem;
}
.checkout__settings__address > .input {
  margin-top: 1.5rem;
}
.checkout__settings__address__manual,
.checkout__settings__address button.checkout__settings__address__manual {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 300;
  color: #8b8b8b;
  text-decoration: underline;
  display: block;
}
@media (max-width: 1200px) {
  .checkout__settings__address__manual,
  .checkout__settings__address button.checkout__settings__address__manual {
    font-size: 0.875rem;
  }
}
.checkout__settings__address__delivery {
  margin-top: 1.5rem;
}
@media (max-width: 1200px) {
  .checkout__settings__address__delivery {
    margin-top: 1rem;
  }
}
.checkout__settings__address__delivery > .input {
  margin-top: 1.5rem;
}
.checkout__settings__items {
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  .checkout__settings__items {
    margin-top: 5.5rem;
  }
}
.checkout__settings__items__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .checkout__settings__items__title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
}
.checkout__settings__items__buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.checkout__settings__items__buttons__button,
.checkout__settings__items__buttons button.checkout__settings__items__buttons__button {
  position: relative;
  margin-top: 2rem;
  margin-right: 2rem;
  width: calc((100% - 2rem * 3) / 4);
  border-radius: 0;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
}
@media (max-width: 1200px) {
  .checkout__settings__items__buttons__button,
  .checkout__settings__items__buttons button.checkout__settings__items__buttons__button {
    margin-top: 1rem;
    margin-right: 1rem;
    width: calc((100% - 1rem) / 2);
  }
}
.checkout__settings__items__buttons__button > svg,
.checkout__settings__items__buttons button.checkout__settings__items__buttons__button > svg {
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  width: 1.25rem;
  height: 1.25rem;
  color: #fa0002;
  opacity: 0;
}
.checkout__settings__items__buttons__button img,
.checkout__settings__items__buttons button.checkout__settings__items__buttons__button img {
  margin-top: -3rem;
  height: 3.5rem;
}
.checkout__settings__items__buttons__button__icon,
.checkout__settings__items__buttons button.checkout__settings__items__buttons__button__icon {
  margin-top: -3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.checkout__settings__items__buttons__button__icon svg,
.checkout__settings__items__buttons button.checkout__settings__items__buttons__button__icon svg {
  margin-bottom: 0.5rem;
  color: #8b8b8b;
  width: 2.5rem;
  height: 2.5rem;
}
.checkout__settings__items__buttons__button__icon span,
.checkout__settings__items__buttons button.checkout__settings__items__buttons__button__icon span {
  text-align: center;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.08;
  color: #000;
}
.checkout__settings__items__buttons__button__price,
.checkout__settings__items__buttons button.checkout__settings__items__buttons__button__price {
  color: #8b8b8b;
  position: absolute;
  margin: auto;
  bottom: 2rem;
  left: 0;
  right: 0;
}
.checkout__settings__items__buttons__button.active,
.checkout__settings__items__buttons button.checkout__settings__items__buttons__button.active {
  border-color: #fa0002;
  box-shadow: none;
}
.checkout__settings__items__buttons__button.active > svg,
.checkout__settings__items__buttons button.checkout__settings__items__buttons__button.active > svg {
  opacity: 1;
}
.checkout__settings__items__buttons__button:hover,
.checkout__settings__items__buttons button.checkout__settings__items__buttons__button:hover {
  box-shadow: none;
}
.checkout__settings__items__buttons__button:nth-child(4n + 4),
.checkout__settings__items__buttons button.checkout__settings__items__buttons__button:nth-child(4n + 4) {
  margin-right: 0;
}
@media (max-width: 1200px) {
  .checkout__settings__items__buttons__button:nth-child(4n + 4),
  .checkout__settings__items__buttons button.checkout__settings__items__buttons__button:nth-child(4n + 4) {
    margin-right: 1rem;
  }
}
@media (max-width: 1200px) {
  .checkout__settings__items__buttons__button:nth-child(2n + 2),
  .checkout__settings__items__buttons button.checkout__settings__items__buttons__button:nth-child(2n + 2) {
    margin-right: 0;
  }
}
.checkout__settings__items__buttons__button::before,
.checkout__settings__items__buttons button.checkout__settings__items__buttons__button::before {
  content: '';
  float: left;
  padding-top: 100%;
}
.checkout__summary {
  margin-left: 10rem;
  width: 37.5rem;
}
@media (max-width: 1200px) {
  .checkout__summary {
    margin-left: 0;
    width: 100%;
    margin-top: 5.5rem;
  }
}
.checkout__summary__content {
  padding: 4rem;
  border-radius: 1rem;
  background-color: #f7f7f7;
}
@media (max-width: 1200px) {
  .checkout__summary__content {
    border-radius: 0;
    margin: 0 -1.625rem -3rem -1.625rem;
    padding: 3rem 1.625rem;
  }
}
.checkout__summary__content__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  color: #030303;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .checkout__summary__content__title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}
.checkout__summary__content__products__product {
  margin-top: 2rem;
}
.checkout__summary__content__products__product__up {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 4rem;
}
@media (max-width: 1200px) {
  .checkout__summary__content__products__product__up {
    min-height: 3rem;
  }
}
.checkout__summary__content__products__product__up__left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checkout__summary__content__products__product__up__left__photo {
  width: 4rem;
  height: 4rem;
}
@media (max-width: 1200px) {
  .checkout__summary__content__products__product__up__left__photo {
    width: 3rem;
    height: 3rem;
  }
}
.checkout__summary__content__products__product__up__left__photo img {
  width: 100%;
  mix-blend-mode: multiply;
}
.checkout__summary__content__products__product__up__info {
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
@media (max-width: 1200px) {
  .checkout__summary__content__products__product__up__info {
    padding: 0 0.5rem;
  }
}
.checkout__summary__content__products__product__up__info__name {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.9px;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .checkout__summary__content__products__product__up__info__name {
    font-size: 0.875rem;
  }
}
.checkout__summary__content__products__product__up__price {
  color: #1f1f1f;
  font-size: 1rem;
  text-align: right;
}
@media (max-width: 1200px) {
  .checkout__summary__content__products__product__up__price {
    font-size: 0.875rem;
  }
}
.checkout__summary__content__products__product__up.with-extensions {
  align-items: flex-start;
}
.checkout__summary__content__products__product__extensions {
  margin-top: -1.875rem;
}
@media (max-width: 1200px) {
  .checkout__summary__content__products__product__extensions {
    margin-top: -1rem;
  }
}
.checkout__summary__content__products__product__extensions__extension {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 7rem;
}
@media (max-width: 1200px) {
  .checkout__summary__content__products__product__extensions__extension {
    padding-left: 3.5rem;
  }
}
.checkout__summary__content__products__product__extensions__extension__name {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .checkout__summary__content__products__product__extensions__extension__name {
    font-size: 0.625rem;
  }
}
.checkout__summary__content__products__product__extensions__extension__name svg {
  margin-left: 0.25rem;
  width: 1rem;
  height: 1rem;
  color: #8b8b8b;
}
@media (max-width: 1200px) {
  .checkout__summary__content__products__product__extensions__extension__name svg {
    width: 0.625rem;
    height: 0.625rem;
  }
}
.checkout__summary__content__products__product__extensions__extension__price {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .checkout__summary__content__products__product__extensions__extension__price {
    font-size: 0.625rem;
  }
}
.checkout__summary__content__products__product__extensions__extension:first-of-type {
  margin-top: 0;
}
.checkout__summary__content__coupon {
  margin-top: 5.5rem;
}
@media (max-width: 1200px) {
  .checkout__summary__content__coupon {
    margin-top: 4rem;
  }
}
.checkout__summary__content__coupon__button,
.checkout__summary__content__coupon button.checkout__summary__content__coupon__button {
  font-weight: 300;
  color: #8b8b8b;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .checkout__summary__content__coupon__button,
  .checkout__summary__content__coupon button.checkout__summary__content__coupon__button {
    font-size: 0.875rem;
  }
}
.checkout__summary__content__coupon__content {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .checkout__summary__content__coupon__content {
    flex-direction: column;
    align-items: flex-end;
  }
}
.checkout__summary__content__coupon__content .input {
  flex: 1;
  margin-right: 5rem;
}
@media (max-width: 1200px) {
  .checkout__summary__content__coupon__content .input {
    margin-right: 0;
  }
}
.checkout__summary__content__coupon__content .input .MuiFilledInput-root {
  background-color: white !important;
}
.checkout__summary__content__coupon__content__button,
.checkout__summary__content__coupon__content button.checkout__summary__content__coupon__content__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  padding: 0 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #1f1f1f;
  height: 3rem;
  border: solid 1px #1f1f1f;
  text-transform: uppercase;
  min-width: 11rem;
  border-radius: 0 !important;
}
@media (max-width: 1200px) {
  .checkout__summary__content__coupon__content__button,
  .checkout__summary__content__coupon__content button.checkout__summary__content__coupon__content__button {
    font-size: 0.875rem;
    height: 2.5rem;
    min-width: 10rem;
  }
}
@media (max-width: 1200px) {
  .checkout__summary__content__coupon__content__button,
  .checkout__summary__content__coupon__content button.checkout__summary__content__coupon__content__button {
    margin-top: 1rem;
    white-space: nowrap;
  }
}
.checkout__summary__content__amounts {
  margin-top: 3rem;
}
.checkout__summary__content__amounts__item {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.checkout__summary__content__amounts__item__name {
  font-size: 1rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .checkout__summary__content__amounts__item__name {
    font-size: 0.875rem;
  }
}
.checkout__summary__content__amounts__item__value {
  font-size: 1rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .checkout__summary__content__amounts__item__value {
    font-size: 0.875rem;
  }
}
.checkout__summary__content__amounts__item:last-of-type {
  margin-bottom: 0;
}
.checkout__summary__content__amount {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.checkout__summary__content__amount__name {
  font-size: 1.5rem;
  color: #000;
}
@media (max-width: 1200px) {
  .checkout__summary__content__amount__name {
    font-size: 1.25rem;
  }
}
.checkout__summary__content__amount__value {
  font-size: 1.375rem;
  color: #000;
}
@media (max-width: 1200px) {
  .checkout__summary__content__amount__value {
    font-size: 1.25rem;
  }
}
.checkout__summary__content__vat {
  margin-top: 1rem;
}
.checkout__summary__content__vat__item {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.checkout__summary__content__vat__item__name {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .checkout__summary__content__vat__item__name {
    font-size: 0.625rem;
  }
}
.checkout__summary__content__vat__item__value {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .checkout__summary__content__vat__item__value {
    font-size: 0.625rem;
  }
}
.checkout__summary__content__vat__item:last-of-type {
  margin-bottom: 0;
}
.checkout__summary__content > .input {
  margin-top: 3rem;
}
@media (max-width: 1200px) {
  .checkout__summary__content > .input {
    margin-top: 4rem;
  }
}
.checkout__summary__content > .input .input__label {
  font-weight: 300;
}
.checkout__summary__content > .input .MuiFilledInput-root {
  background-color: white !important;
}
.checkout__summary__content > .checkbox {
  margin-top: 1.5rem;
  align-items: flex-start;
}
@media (max-width: 1200px) {
  .checkout__summary__content > .checkbox {
    margin-top: 1rem;
  }
}
.checkout__summary__content > .checkbox > span {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .checkout__summary__content > .checkbox > span {
    font-size: 0.625rem;
  }
}
.checkout__summary__content__down {
  margin-top: 2rem;
}
.checkout__summary__content__down__button,
.checkout__summary__content__down button.checkout__summary__content__down__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fa0002;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  border: 0 !important;
  border-radius: 0 !important;
  width: 100%;
}
@media (max-width: 1200px) {
  .checkout__summary__content__down__button,
  .checkout__summary__content__down button.checkout__summary__content__down__button {
    font-size: 0.875rem;
  }
}
.checkout__summary__content__down__text {
  margin-top: 1.5rem;
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .checkout__summary__content__down__text {
    margin-top: 1rem;
    font-size: 0.625rem;
  }
}
.checkout__summary__content__down__text button {
  color: #8b8b8b;
  text-decoration: underline;
  margin: 0 0.25rem;
  font-size: 0.75rem;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .checkout__summary__content__down__text button {
    font-size: 0.625rem;
  }
}
.checkout__summary__content__down-fixed {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: calc(100% - 1.5rem * 2);
  height: 7rem;
  box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: #f7f7f7;
  padding: 1.5rem;
  z-index: 100;
}
.checkout__summary__content__down-fixed .checkout__summary__content__down {
  margin-top: 0;
}
@media (max-width: 1200px) {
  .checkout__summary__content__down-fixed {
    display: block;
  }
}

@import 'variables';

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
        display: flex;
        flex-direction: row;
        width: 100%;

        @media (max-width: @mobileBreakpoint) {
            flex-direction: column-reverse;
        }

        &__map {
            margin-right: 10rem;
            min-width: 46rem;
            width: 46rem;
            height: 46rem;
            background-color: @colorNeutral1;
            overflow: hidden;

            @media (max-width: @mobileBreakpoint) {
                margin-right: 0;
                width: 100%;
                min-width: 100%;
                height: 20rem;
            }

            iframe {
                width: 100%;
                height: 100%;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &__up {
                &__title {
                    .fontBarlow();

                    font-size: 5rem;
                    font-weight: bold;
                    font-style: italic;
                    color: #1f1f1f;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 3.5rem;
                    }
                }

                &__text {
                    margin-top: @spacingXL;
                    line-height: 1.8;
                    color: #1f1f1f;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingL;
                        font-size: 0.875rem;
                    }

                    span {
                        margin: 0 0.25rem;
                        font-weight: 600;
                    }
                }

                &__phone {
                    margin-top: @spacing2XL;
                    font-size: 1.5rem;
                    color: @colorNeutral2;
                    display: flex;
                    flex-direction: row;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1.25rem;
                        margin-top: @spacingXL;
                    }

                    svg {
                        margin-right: @spacingS;
                        width: 1.875rem;
                        height: 1.875rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: 1.5rem;
                            height: 1.5rem;
                        }
                    }
                }

                &__email {
                    font-size: 1.5rem;
                    color: @colorNeutral2;
                    display: flex;
                    flex-direction: row;
                    margin-top: @spacingS;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1.25rem;
                    }

                    svg {
                        margin-right: @spacingS;
                        width: 1.875rem;
                        height: 1.875rem;

                        @media (max-width: @mobileBreakpoint) {
                            width: 1.5rem;
                            height: 1.5rem;
                        }
                    }
                }
            }

            &__down {
                display: flex;
                flex-direction: row;

                @media (max-width: @mobileBreakpoint) {
                    flex-direction: column;
                    margin-top: @spacing2XL;
                    align-items: center;
                }

                &__panel {
                    flex: 1;

                    @media (max-width: @mobileBreakpoint) {
                        margin-bottom: @spacingXL;
                        width: 10.25rem;
                    }

                    &__title {
                        .fontBarlow();

                        font-size: 2rem;
                        font-weight: bold;
                        font-style: italic;
                        color: @colorDark;
                        text-transform: uppercase;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1.5rem;
                        }
                    }

                    &__text {
                        margin-top: @spacingM;
                        display: flex;
                        flex-direction: column;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: @spacingS;
                        }

                        span {
                            width: 100%;
                            font-size: 1rem;
                            color: #1f1f1f;
                            line-height: 1.8;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.875rem;
                            }
                        }

                        .mini {
                            margin-top: @spacingS;
                            font-size: 0.75rem;
                            line-height: 1.4;
                            color: #1f1f1f;
                            max-width: 12.5rem;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 0.625rem;
                            }
                        }
                    }
                }
                &__panel:first-of-type {
                    margin-right: @spacingL;

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &__persons {
        margin-top: @spacing2XL;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 62rem;

        @media (max-width: @mobileBreakpoint) {
            width: 100%;
            flex-direction: column;
            align-items: center;
        }

        &__person {
            margin-top: @spacing5XL;
            margin-right: 10rem;
            width: 14rem;

            @media (max-width: @mobileBreakpoint) {
                margin-top: 0;
                margin-bottom: @spacing3XL;
                margin-right: 0;
            }

            >span {
                margin: 0 0 0 0.25rem;
                text-decoration: underline;
            }

            &__photo {
                width: 14rem;
                height: 18.6875rem;
                background-color: @colorNeutral1;
                border-radius: 1rem;
                overflow: hidden;

                img {
                    width: 100%;
                }
            }

            &__title {
                margin-top: @spacingM;
                font-size: 0.75rem;
                color: #1f1f1f;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.625rem;
                }
            }

            &__name {
                .fontBarlow();

                margin-top: @spacingXXS;
                font-size: 1.5rem;
                font-weight: bold;
                font-style: italic;
                color: #1f1f1f;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.25rem;
                }
            }

            &__email {
                margin-top: @spacingM;
                display: flex;
                flex-direction: row;
                font-size: 1rem;
                color: @colorNeutral2;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }

                svg {
                    margin-right: @spacingS;
                    width: 1rem;
                    height: 1rem;

                    @media (max-width: @mobileBreakpoint) {
                        width: 0.875rem;
                        height: 0.875rem;
                    }
                }
            }

            &__phone {
                margin-top: @spacingXXS;
                display: flex;
                flex-direction: row;
                font-size: 1rem;
                color: @colorNeutral2;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }

                svg {
                    margin-right: @spacingS;
                    width: 1rem;
                    height: 1rem;

                    @media (max-width: @mobileBreakpoint) {
                        width: 0.875rem;
                        height: 0.875rem;
                    }
                }
            }
        }
        &__person:first-of-type {
            font-size: 1.5rem;
            line-height: 1.4;
            color: #1f1f1f;
            position: relative;

            @media (max-width: @mobileBreakpoint) {
                font-size: 1.25rem;
                width: 22rem;
            }

            .contact__persons__person__text {
                width: 22rem;
                position: absolute;
                right: 0;
                top: 0;

                @media (max-width: @mobileBreakpoint) {
                    position: relative;
                    text-align: center;
                }
            }
        }
        &__person:nth-child(3n + 3) {
            margin-right: 0;
        }
    }
}

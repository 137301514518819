.cart-content {
  width: 43.75rem;
}
@media (max-width: 1200px) {
  .cart-content {
    width: 100%;
  }
}
.cart-content__products__product {
  margin-bottom: 2rem;
}
@media (max-width: 1200px) {
  .cart-content__products__product {
    margin-bottom: 4rem;
  }
}
.cart-content__products__product__up {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 4rem;
  width: 100%;
}
.cart-content__products__product__up__left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cart-content__products__product__up__left button {
  margin-right: 1.5rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1200px) {
  .cart-content__products__product__up__left button {
    margin-right: 0.75rem;
  }
}
.cart-content__products__product__up__left button svg {
  width: 1.25rem;
  height: 1.25rem;
  color: #8b8b8b;
}
.cart-content__products__product__up__left__photo {
  width: 4rem;
  height: 4rem;
}
@media (max-width: 1200px) {
  .cart-content__products__product__up__left__photo {
    width: 3rem;
    height: 3rem;
  }
}
.cart-content__products__product__up__left__photo img {
  width: 100%;
  mix-blend-mode: multiply;
}
.cart-content__products__product__up__right {
  display: flex;
  flex-direction: row;
  flex: 1;
}
@media (max-width: 1200px) {
  .cart-content__products__product__up__right {
    flex-wrap: wrap;
    margin-left: 2rem;
  }
}
.cart-content__products__product__up__right__info {
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
@media (max-width: 1200px) {
  .cart-content__products__product__up__right__info {
    padding: 0;
    min-width: 100%;
  }
}
.cart-content__products__product__up__right__info__name {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.9px;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .cart-content__products__product__up__right__info__name {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
  }
}
.cart-content__products__product__up__right__counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -0.5rem;
}
@media (max-width: 1200px) {
  .cart-content__products__product__up__right__counter {
    margin-top: -0.5rem;
  }
}
.cart-content__products__product__up__right__counter__button,
.cart-content__products__product__up__right__counter button.cart-content__products__product__up__right__counter__button {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 100%;
}
.cart-content__products__product__up__right__counter__button svg,
.cart-content__products__product__up__right__counter button.cart-content__products__product__up__right__counter__button svg {
  width: 1.125rem;
  height: 1.125rem;
  color: #8b8b8b;
}
.cart-content__products__product__up__right__counter__button:hover,
.cart-content__products__product__up__right__counter button.cart-content__products__product__up__right__counter__button:hover {
  background-color: #f5f5f5;
}
.cart-content__products__product__up__right__counter__value {
  min-width: 1.875rem;
  width: 1.875rem;
  max-width: 1.875rem;
  font-size: 1rem;
  text-align: center;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .cart-content__products__product__up__right__counter__value {
    font-size: 0.875rem;
    width: 1rem;
    min-width: 1rem;
    max-width: 1rem;
  }
}
.cart-content__products__product__up__right__price {
  font-weight: 500;
  color: #000;
  width: 8.75rem;
  text-align: right;
}
@media (max-width: 1200px) {
  .cart-content__products__product__up__right__price {
    font-size: 0.875rem;
    width: auto;
    flex: 1;
  }
}
.cart-content__products__product__up.with-extensions {
  align-items: flex-start;
}
.cart-content__products__product__extensions {
  margin-top: -1.875rem;
}
@media (max-width: 1200px) {
  .cart-content__products__product__extensions {
    margin-top: 0.75rem;
  }
}
.cart-content__products__product__extensions__extension {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 9.75rem;
}
@media (max-width: 1200px) {
  .cart-content__products__product__extensions__extension {
    padding-left: 7.25rem;
  }
}
.cart-content__products__product__extensions__extension__name {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .cart-content__products__product__extensions__extension__name {
    font-size: 0.625rem;
  }
}
.cart-content__products__product__extensions__extension__name svg {
  margin-left: 0.25rem;
  width: 1rem;
  height: 1rem;
  color: #8b8b8b;
}
.cart-content__products__product__extensions__extension__price {
  font-size: 0.75rem;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .cart-content__products__product__extensions__extension__price {
    font-size: 0.625rem;
  }
}
.cart-content__products__product__extensions__extension:first-of-type {
  margin-top: 0;
}
.cart-content__products__product:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 1200px) {
  .cart-content__down {
    display: block;
    margin-top: 0;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 2rem;
    width: calc(100% - 2rem * 2);
    box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.16);
    background-color: #f7f7f7;
  }
}
.cart-content__down__price {
  margin-top: 5.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .cart-content__down__price {
    margin-top: 0;
  }
}
.cart-content__down__price__text {
  font-size: 1.5rem;
  color: #000;
}
@media (max-width: 1200px) {
  .cart-content__down__price__text {
    font-size: 1.25rem;
  }
}
.cart-content__down__price__amount {
  font-size: 1.5rem;
  color: #000;
}
@media (max-width: 1200px) {
  .cart-content__down__price__amount {
    font-size: 1.25rem;
  }
}
.cart-content__down__button,
.cart-content__down button.cart-content__down__button {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  height: 3.5rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fa0002;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.8px;
  color: #fff;
  text-transform: uppercase;
  border: 0 !important;
  border-radius: 0 !important;
  margin-top: 2rem;
  width: 100%;
}
@media (max-width: 1200px) {
  .cart-content__down__button,
  .cart-content__down button.cart-content__down__button {
    font-size: 0.875rem;
  }
}
@media (max-width: 1200px) {
  .cart-content__down__button,
  .cart-content__down button.cart-content__down__button {
    margin-top: 3rem;
  }
}

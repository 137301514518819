@font: 1rem;
@pcBreakpoint: 1599px;
@mobileBreakpoint: 1200px;

@spacingXXS: 0.5rem;
@spacingXS: 0.75rem;
@spacingS: 1rem;
@spacingM: 1.5rem;
@spacingL: 2rem;
@spacingXL: 3rem;
@spacing2XL: 4rem;
@spacing3XL: 5.5rem;
@spacing4XL: 7rem;
@spacing5XL: 9rem;
@spacing6XL: 11rem;
@spacing7XL: 13.5rem;
@rem10: 160px;

@colorNeutral1: #f5f5f5;
@colorNeutral2: #8b8b8b;
@colorNeutral3: #e1e1e1;
@colorDark: #030303;
@colorBaseColor: #fdfdfd;
@colorOrange: #f39a33;
@colorGreen: #409b04;
@colorRed: #c24646;

@colorPrimary: #fa0002;
@colorBlack: black;

.font() {
    font-family: 'Red Hat Text', sans-serif !important;
    font-size: @font;
    font-style: normal;
    font-weight: normal;
}

.fontBarlow() {
    font-family: 'Barlow Condensed', sans-serif !important;
    font-size: @font;
    font-style: normal;
    font-weight: normal;
}

.button() {
    .fontBarlow();

    padding: 0 @spacingS;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0.8px;
    color: #1f1f1f;
    height: 3rem;
    border: solid 1px #1f1f1f;
    text-transform: uppercase;
    min-width: 11rem;
    border-radius: 0 !important;

    @media (max-width: @mobileBreakpoint) {
        font-size: 0.875rem;
        height: 2.5rem;
        min-width: 10rem;
    }
}

.buttonPrimary() {
    .fontBarlow();

    height: 3.5rem;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: @colorPrimary;
    font-size: @font;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0.8px;
    color: #fff;
    text-transform: uppercase;
    border: 0 !important;
    border-radius: 0 !important;

    @media (max-width: @mobileBreakpoint) {
        font-size: 0.875rem;
    }
}

.rotate(@deg) {
    -webkit-transform: rotate(@deg);
    transform: rotate(@deg);
}
